let articlesArray = [
    {
        id: 1,
        preview: "images/article-preview-1.webp",
        tag: "crisis",
        name: "Energy crisis",
        paragraph1:
            "The fear with the energy crisis is that as the demand for the finite natural resources utilized to power modern society increases, supply will not keep up. There is a finite amount of these natural resources. Although they do naturally develop, it would still take a thousand ls of years for it to develop in large quantities. ",
        paragraph2:
            "Governments and concerned citizens are collaborating to prioritize the use of renewable resources and to reduce the careless use of natural resources by practicing improved conservation.",
        paragraph3:
            "The energy crisis is a vast and intricate subject. Most people are not aware of its actuality till petrol prices increase or there are long queues at the gas station. Despite numerous efforts, the energy problem persists and only gets worse. ",
        paragraph4:
            "This is because efforts to address the complex causes and solutions of the energy problem cannot be made since there is not a widespread knowledge of them.",
        subtitle5: "Energy crisis: How real is it?",
        paragraph5:
            "How real the energy crisis is, is best summed up by the proposition that increasing demands on finite resources will inevitably result in their depletion. ",
        paragraph6:
            "That makes complete sense. What is actually at stake in the debate over how real the energy crisis is has to do with how people feel about their future duty. If you aren't worried about what will happen after you leave this planet, there isn't really an energy crisis. If you care about leaving a better world for future generations, there is a real energy issue.",
        subtitle7: "Causes of Energy Crisis ",
        paragraph7:
            "There are several causes of the global energy crisis. The mistake most people make is to point fingers at certain industries, however, that is a very naive thing to do. Here are some of the causes:",
        mediumText8: "Overpopulation",
        paragraph8:
            "The world's population has been steadily growing along with its need for products and fuel, which has been a contributing factor in the dilemma. Whichever food or items you choose to consume, whether they are fair trade, organic, or made in a sweatshop using petroleum products, not one of them is produced or transported without having a substantial impact on our energy resources.",
        mediumText9: "Overconsumption",
        paragraph9:
            "Many stresses on our natural resources, not just one, are to blame for the current energy crisis. Overuse of fossil fuels, such as oil, gas, and coal, is putting a pressure on these resources, which can subsequently result in pollution, which can have a negative impact on our water and oxygen supplies. ",
        mediumText11: "Inadequate infrastructure ",
        paragraph11:
            "Another factor contributing to the energy deficit is the infrastructure of the power generation equipment. Most energy-producing companies continue to use out-of-date machinery, which limits the amount of energy that can be produced. Utilities are in charge of maintaining infrastructure upgrades and establishing high performance standards.",
        mediumText12: "Unexplored Sources of Renewable Energy ",
        paragraph12:
            "In the majority of nations, renewable energy is currently underutilized. Coal is one of the main non-renewable energy sources. As a result, it continues to be the best option for producing energy. Until we start to tackle the issues and give serious thought to renewable energy, it’ll be impossible to solve the problem of energy crisis. More sources of renewable energy can allow us to reduce the consumption of fossil fuels and reduce gas emissions from the greenhouse. ",
        mediumText13: "Energy wastage",
        paragraph14:
            "Most people around the world do not understand how important energy conservation is. Books, the Internet, newspaper ads, lip service, and seminars are the only sources that are available to bring awareness. Things won't change any time soon until we give it serious consideration. Things as simple as turning off electrical appliances when not in use, walking and not driving short distances, and repairing leakages can critically help.",
        mediumText15: "Delay in Power Plant Commissioning ",
        paragraph15:
            "The opening of new power plants that can close the energy supply-demand imbalance are experiencing substantial delays in a few nations. Old plants are put under a lot of stress as a result of the everyday demand for electricity. Load-shedding and breakdown occur when supply and demand are out of balance.",
        mediumText16: "Attacks and Wars",
        paragraph16:
            "Conflicts between nations can also disrupt the flow of energy, particularly if they take place in Middle Eastern nations like Saudi Arabia, Iraq, Iran, Kuwait, the United Arab Emirates, or Qatar. That is what occurred in 1990 during the Gulf War, when oil prices were at their highest, leading to global shortages and serious issues for energy consumers.",
        subtitle17: "Effects of energy crisis ",
        paragraph17:
            "The development of human civilization has increased our reliance on conventional energy sources. The most basic energy source is priceless fossil fuels. All of these sources will inevitably result in specific outcomes when used. The following are some significant implications of the global energy crisis:",
        mediumText18: "Impact on the environment ",
        articleImg18: "/images/article-1-img-1.webp",
        paragraph19:
            "Fossil fuels that are not renewable are burned to provide energy. The environment is also impacted by this, in addition to the world's supply of fossil fuels. When fossil fuels are burned, greenhouse gases like carbon dioxide and others are released. ",
        paragraph20:
            "The earth's surface is covered by these gases, blocking the sun's brief nighttime rays from reaching the surface. Hence, through boosting global warming, the energy problem helps make the globe a warmer environment.",
        mediumText21: "A rise in the cost of fuel resources",
        paragraph21:
            "The price of fossil fuels rises in tandem with their increased use. It is important to keep in mind that the supply of these fossil fuels is finite. These fossil fuels are becoming much more scarce as we continue to use them. ",
        paragraph22:
            "The demand for these fuels rises daily as time goes on, whereas the amount of supply falls everyday. This has a significant impact on the cost of fossil fuels, driving up the price continuously. This has a significant negative impact on the global economy.",
        mediumText23: "Impact on the Travel and Tourism Sector ",
        paragraph23:
            "The peaks and troughs in fuel prices have a significant impact on the tourism sector. The tourism business is significantly impacted by the dramatic increase in fuel prices that results from the energy crisis. ",
        paragraph24:
            "Costs associated with tourism have increased as a result of the rise in fuel prices. Many people are unable to afford the same as a result of this. And as a result of everything, the tourism sector suffers.",
        subtitle25: "Conclusion",
        paragraph25:
            "Energy crisis is a serious and important issue globally. It is very important that countries, governments, and even ordinary people start to be more deliberate about ways to prevent or at least reduce it. Energy crisis affects everyone no matter who you are, choosing to save yourself shouldn’t be a difficult decision. ",
    },
    {
        id: 2,
        preview: "images/article-preview-2.webp",
        tag: "world coal",
        name: "Chinese power plants as the main consumer of energy coal on the planet.",
        paragraph1:
            "China holds the title for being the top coal consumer and producer worldwide. It predominantly depends on coal-fired power plants to cater to the surging energy needs of its industries, homes, and transportation sectors. In fact, the Chinese power plants are the principal consumers of energy coals, burning substantial quantities of coal to generate electricity. Unfortunately, this reliance on coal power plants has resulted in detrimental environmental outcomes, including air pollution and greenhouse gas emissions.",

        subtitle2: "",
        mediumText2: "",
        paragraph2:
            "Explore with us as we delve into the energy consumption trends in China and unravel everything you should know about the country's coal-dependent power plants.",
        articleImg2: "",

        subtitle3:
            "Energy consumption and dependence on coal power plants in China",
        mediumText3: "",
        paragraph3:
            "Over the past few decades, China has witnessed an unprecedented surge in its energy consumption, driven by rapid economic development and urbanization. The country's insatiable appetite for electricity has made it the largest consumer of energy in the world, with a whopping 157 exajoules consumed in 2021.",
        articleImg3: "",

        subtitle4: "",
        mediumText4: "",
        paragraph4:
            "The manufacturing sector has played a pivotal role in fueling China's energy consumption, growing at an astounding pace in recent years. Additionally, the Chinese government's significant investments in infrastructure development, such as roads, railways, and airports, have further amplified energy demand.",
        articleImg4: "",

        subtitle5: "",
        mediumText5: "",
        paragraph5:
            "Coal has been China's primary energy source, accounting for over 57% of the country's total energy consumption. As both the world's largest producer and consumer of coal, China heavily relies on this fossil fuel to power its economy. However, this has also made China the largest greenhouse gas emitter globally, contributing significantly to climate change.",
        articleImg5: "",

        subtitle6: "",
        mediumText6: "",
        paragraph6:
            "Despite the high levels of energy consumption, China still faces energy shortages and supply challenges, especially during peak demand periods. While the country has taken steps towards diversifying its energy mix by investing in renewable sources like solar and wind power, the transition has been slow. The nation's dependence on coal continues to pose a significant challenge for its sustainable development objectives.",
        articleImg6: "",

        subtitle7: "Environmental Impacts of Coal Power Plants in China",
        mediumText7: "",
        paragraph7:
            "For decades, coal power plants have served as the primary source of electricity generation in China. However, with growing environmental concerns, the impact of these plants is becoming more pressing. Notable environmental impacts include: ",
        articleImg7: "",

        subtitle8: "",
        mediumText8: "Air pollution",
        paragraph8: "",
        articleImg8: "/images/article-2-img-1.webp",
        subtitle9: "",
        mediumText9: "",
        paragraph9:
            "Did you know that China is among the countries with the most severe air pollution levels globally? Unfortunately, coal-fired power plants are the primary culprits for the toxic emissions that wreak havoc on the air quality. These plants emit a dangerous cocktail of pollutants, including sulfur dioxide, nitrogen oxides, particulate matter, and mercury. Sadly, these substances are notorious for causing respiratory illnesses, cardiovascular disease, and even premature death. To make matters worse, the smog resulting from these emissions also impacts visibility and poses economic risks such as decreased tourism.",
        articleImg9: "",

        subtitle10: "",
        mediumText10: "Water pollution",
        paragraph10:
            "China's coal-fired power plants heavily rely on water for cooling, but the release of heated water into rivers and lakes poses a serious threat to aquatic life. This is due to the thermal pollution they cause. Furthermore, the disposal of coal ash, a residual product of coal combustion, can result in groundwater and surface water contamination by toxic heavy metals such as cadmium, arsenic, and lead.",
        articleImg10: "",

        subtitle11: "",
        mediumText11: "Global warming and climate change ",
        paragraph11:
            "Carbon dioxide emissions from coal power plants contribute to global warming and climate change. China is the world's largest emitter of greenhouse gases, with coal power plants accounting for over half of the country's carbon dioxide emissions. These emissions have contributed to rising temperatures, sea-level rise, and extreme weather events, which have severe consequences for people, animals, and ecosystems worldwide.",
        articleImg11: "",

        subtitle12: "",
        mediumText12: "Acid Rain",
        paragraph12:
            "China's coal power plants emit gases that combine with water vapor in the atmosphere to create sulfuric acid and nitric acid, which ultimately result in acid rain. This phenomenon causes damage to forests, crops, and aquatic life. Acid rain is a hazardous outcome of the interaction between sulfur dioxide, nitrogen oxides, and water vapor. These acids fall back to earth as rain, snow, or dry particles, impacting the environment adversely.",
        articleImg12: "",

        subtitle13:
            "Alternative sources of energy and their potential for adoption in China",
        mediumText13: "",
        paragraph13: "",
        articleImg13: "/images/article-2-img-2.webp",

        subtitle14: "",
        mediumText14: "",
        paragraph14:
            "Alternative sources of energy have become increasingly popular in recent years as concerns over climate change and environmental pollution have grown. In China, alternative sources of energy offer a promising way to reduce dependence on coal and its negative impact on the environment. Here are some alternative sources of energy that have potential for adoption in China:",
        articleImg14: "",

        subtitle15: "",
        mediumText15: "",
        paragraph15:
            "● Solar Energy: China is the world's largest producer and user of solar energy. The country boasts of an impressive solar capacity of more than 350GW. ",

        subtitle16: "",
        mediumText16: "",
        paragraph16:
            "With abundant sunlight and a favorable policy environment, solar energy has great potential to replace coal in China's energy mix.",
        articleImg16: "",

        subtitle17: "",
        mediumText17: "",
        paragraph17:
            "● Wind Energy: China is also the world's largest producer and user of wind energy. In 2021, wind power generated 7.5% of China's total electricity. ",

        subtitle18: "",
        mediumText18: "",
        paragraph18:
            "With the government's goal of reaching 20% of electricity generation from non-fossil fuels by 2025, wind energy is expected to play an increasingly important role in China's energy supply.",
        articleImg18: "",

        subtitle19: "",
        mediumText19: "",
        paragraph19:
            "● Hydro Power: China is also rich in hydropower resources, with the world's largest installed capacity. The country has been investing heavily in hydropower development, especially in the southwestern region. Hydropower has the advantages of being renewable, clean, and flexible, making it an ideal alternative to coal power plants. ",
        articleImg19: "",

        subtitle20: "",
        mediumText20: "",
        paragraph20:
            "● Nuclear Power: Despite concerns over safety, nuclear power has become an increasingly popular alternative to coal power in China. The country has been investing heavily in nuclear power to meet its growing energy demands and it currently has 50 nuclear reactors in operation and under construction, with a total capacity of 53 GW. The Chinese government plans to increase the country's nuclear capacity to 70 GW by 2025.",
        articleImg20: "",

        subtitle21: "",
        mediumText21: "",
        paragraph21:
            "● Biomass Energy: Biomass energy, such as biogas and biofuels, can be produced from agricultural and forestry waste. China has been promoting the use of biomass energy, especially in rural areas, as part of its efforts to improve energy access and reduce poverty. With the country’s large agricultural and forestry resources, biomass energy has great potential to replace coal in rural areas.",
        articleImg21: "",

        subtitle22: "Bottomline",
        mediumText22: "",
        paragraph22:
            "The Chinese government has shown a growing commitment to reducing its dependence on coal power plants and transition towards cleaner sources of energy. While there are still significant challenges to overcome, the prospects for reducing coal dependency in Chinese power plants is promising. If China can successfully transition towards cleaner sources of energy, it could have a significant impact on global efforts to reduce carbon emissions and combat climate change.",
        articleImg22: "",
    },
    {
        id: 3,
        preview: "images/article-preview-3.webp",
        tag: "world coal",
        name: "Digging Deeper: An Exploration of Different Mining Methods",
        paragraph1:
            "Mining is a crucial activity that has been instrumental in the development of human civilizations for thousands of years. Over time, different mining methods have emerged, each with its unique advantages and disadvantages. From underground mining, which involves extracting minerals from beneath the earth's surface, to open surface mining, placer mining, and in-situ mining, each method offers distinct benefits and challenges. ",

        subtitle2: "",
        mediumText2: "",
        paragraph2: "",
        articleImg2: "",

        subtitle3: "",
        mediumText3: "",
        paragraph3:
            "In this article, we'll delve into each of these mining methods, their pros and cons, and factors to consider when choosing a mining method. So buckle up and get ready to explore the fascinating world of mining!",
        articleImg3: "",

        subtitle4: "1. Underground mining",
        mediumText4: "",
        paragraph4: "",
        articleImg4: "/images/article-3-img-1.webp",

        subtitle5: "",
        mediumText5: "",
        paragraph5:
            "This is the first mining method we will consider, and it is one of the oldest and most traditional ways of extracting minerals from the earth's crust. Underground mining is a method of mineral extraction that involves the excavation of tunnels and shafts deep into the earth's crust to reach mineral deposits. Despite the inherent difficulties and hazards associated with this method, it remains a critical means of accessing mineral resources that are not readily available through open surface mining. ",
        articleImg5: "",

        subtitle6: "",
        mediumText6: "",
        paragraph6:
            "This is due to the fact that the deposits are often located deep underground and require specialized equipment and techniques to extract. Moreover, in many cases, underground mining is the only viable option for extracting certain minerals. This is in areas where surface mining would have a devastating impact on the environment. As a result, this mining method is ideal for extracting minerals such as gold, coal, and diamonds, which are frequently found deep beneath the surface.",
        articleImg6: "",
        table6: {
            tableHeader1: "Advantages ",
            tableHeader2: "Disadvantages ",
            tableItem1:
                "it allows for extraction of minerals not accessible by open surface mining",
            tableItem2:
                "it minimizes  the impact of the mining process on the environment",
            tableItem11: "it is a hazardous and costly process ",
            tableItem12:
                "it has a high risk of cave-Ins, explosions and exposure to harmful gases",
        },
        subtitle7: "2. Open surface mining",
        mediumText7: "",
        paragraph7: "",
        articleImg7: "/images/article-3-img-2.webp",

        subtitle8: "",
        mediumText8: "",
        paragraph8:
            "Open surface mining, also known as open-pit mining, is a mining technique used to extract minerals that are located near the surface of the earth. This method involves the removal of overlying soil and rock, exposing the mineral deposits and making them accessible for extraction. Open surface mining is commonly used for minerals such as coal, copper, gold, iron, and other precious metals.",
        articleImg8: "",

        subtitle9: "",
        mediumText9: "",
        paragraph9:
            "The process of open surface mining begins with the removal of vegetation and topsoil from the mining area. Once this is done, explosives are used to break up the rock and remove the overburden, which is the layer of soil and rock covering the mineral deposits. The extracted materials are then transported to a processing plant, where they are refined and prepared for sale.",
        articleImg9: "",

        table9: {
            tableHeader1: "Advantages ",
            tableHeader2: "Disadvantages ",
            tableItem1: "It is a cost-effective method of mining ",
            tableItem2: "it can be used to extract minerals from large areas ",
            tableItem3:
                "it may cause environmental damages like eruption and water pollution ",
            tableItem4:
                "it may affect the health and well-being of nearby communities",
        },

        subtitle10: "3. Placer mining",
        mediumText10: "",
        paragraph10:
            "Placer mining is a method of extracting minerals that have been deposited in river beds and sedimentary deposits. This method involves the use of gravity to separate the valuable minerals from the sediment, sand, and gravel. Placer mining is commonly used for minerals such as gold, tin, and platinum.",
        articleImg10: "",

        subtitle11: "",
        mediumText11: "",
        paragraph11:
            "The process of placer mining involves using water to wash the sediment and gravel to remove the minerals. This can be done using a pan, sluice box, or dredging equipment. The extracted minerals are then separated from the water and processed to remove impurities and prepare them for sale.",
        articleImg11: "",

        table11: {
            tableHeader1: "Advantages ",
            tableHeader2: "Disadvantages ",
            tableItem1: "it is a simple and low-cost method ",
            tableItem2:
                "it is suitable for areas with rugged terrain and limited water supply ",
            tableItem3:
                "it causes erosions of riverbanks and disruption of aquatic ecosystem",
            tableItem4:
                "it may have negative impacts on nearby residential areas ",
        },

        subtitle12: "4. In-Situ mining ",
        mediumText12: "",
        paragraph12:
            "In-situ mining is a complex and specialized mining technique that involves the dissolution of minerals in place, without the need for surface excavation. It is typically used for the extraction of uranium, copper, and other metals that are located deep underground. In-situ mining is an attractive option for mining companies since it requires fewer workers and less equipment compared to traditional mining methods, which can reduce the overall cost of mining.",
        articleImg12: "",

        subtitle13: "",
        mediumText13: "",
        paragraph13:
            "The in-situ mining process involves the injection of a leaching solution into the ground, which dissolves the targeted minerals. The solution is then pumped back to the surface, where it is processed to extract the desired minerals. The dissolved minerals are left behind in the subsurface, and the mine site is typically reclaimed after the operation is completed.",
        articleImg13: "",

        table13: {
            tableHeader1: "Advantages ",
            tableHeader2: "Disadvantages ",
            tableItem1: "it has low impact on the environment ",
            tableItem2:
                "it can access mineral deposits that are difficult to reach with traditional methods ",
            tableItem3: "it can be slow and expensive ",
            tableItem4: "it requires extensive planning and management ",
        },

        subtitle14: "Factors to consider before choosing a mining method",
        mediumText14: "",
        paragraph14:
            "Before deciding on a mining method for extracting minerals from the earth, several factors must be considered. Some of the factors that mining companies typically consider when choosing a method include",
        articleImg14: "",

        list14: {
            listItem1: "1. The type and quality of the ore",
            listItem2: "2. The depth and location of the deposit",
            listItem3: "3. The size and shape of the deposit",
            listItem4: "4. The environmental impact of the mining method",
            listItem5: "5. The cost and feasibility of the mining method",
            listItem6:
                "6. The safety and health risks associated with the mining method",
            listItem7: "7. The availability and cost of labor and equipment",
            listItem8: "8. The regulatory requirements and permitting process",
            listItem9:
                "9. The social and community impacts of the mining method",
            listItem10:
                "10. The potential for future development and expansion of the mine",
        },

        subtitle15: "Conclusion ",
        mediumText15: "",
        paragraph15:
            "As technology and innovation continue to advance, the mining industry can look forward to new and innovative mining methods that are safer, more efficient, and more sustainable. From advances in automation and robotics to the use of renewable energy and green technologies, the future of mining is filled with possibilities. And as the rising demand for minerals resources increase, mining companies must balance economic development with environmental and community protection. Responsible mining practices should prioritize safety, minimize environmental impact, and respect local communities. This would contributed to the sustainability of the industry and the planet as a whole ",
        articleImg15: "",
    },
    {
        id: 4,
        preview: "images/article-preview-4.webp",
        tag: "export of coal",
        name: "Countries at the forefront of coal mining",
        paragraph1:
            "Over the years, coal has become one of the most important natural resources in the world. It is used in various companies to generate thermal energy for manufacturing processes, such as steelmaking. Coal plays a significant role in powering industrialization in global and local markets. The world’s biggest coal-producing countries work tirelessly to ensure it is delivered consistently to different parts of the world. ",

        subtitle2: "Introduction",
        mediumText2: "",
        paragraph2: "",
        articleImg2: "/images/article-4-img-1.png",

        subtitle3: "",
        mediumText3: "",
        paragraph3: "",
        articleImg3: "",

        subtitle4: "",
        mediumText4: "",
        paragraph4:
            "Recently, the decarbonization of the world energy system and climate unpredictability has reduced the production and supply of coal. However, it is still one of the most important sources of electricity in the world. According to The International Energy Agency (IEA), it contributes to about 38% of the global energy supply. For this reason, the importance of coal in powering industrialization cannot be overemphasized. ",
        articleImg4: "",

        subtitle5: "",
        mediumText5: "",
        paragraph5:
            "According to studies, coal production and supply have increased yearly since 2016. The International Energy Agency measured a record of 7.9 billion tons in 2019. Studies showed that its production was growing at 1.5% per year. Lignite and thermal coal accounted for about 86%, while metallurgical coal accounted for 14%. The global coal reserve is said to contain over one trillion tons. In 2020, The International Energy Agency recorded a 6.5% reduction in global production. This decline was due to a fall in demand during the COVID-19 pandemic. In 2021, the annual coal production was increased to about 7.6 million tons.",
        articleImg5: "",

        subtitle6: "",
        mediumText6: "",
        paragraph6:
            "The rate of coal production continues to increase in different countries worldwide. As a result, industrialization and manufacturing processes continue to flourish. Continue reading to learn about countries that contribute significantly to the production and supply of coal in the global market. ",
        articleImg6: "",

        tableLarge6: {
            tableHeader1: "S/N",
            tableHeader2: "Country",
            tableHeader3: "Total Global Output",
            tableItem1: "1.",
            tableItem2: "2.",
            tableItem3: "3.",
            tableItem4: "4.",
            tableItem5: "5.",
            tableItem6: "6.",
            tableItem7: "China",
            tableItem8: "India",
            tableItem9: "United States",
            tableItem10: "Indonesia",
            tableItem11: "Australia",
            tableItem12: "Russia",
            tableItem13: "3.9 billion tons",
            tableItem14: "767 million tons",
            tableItem15: "544 million tons",
            tableItem16: "550 million tons",
            tableItem17: "544 million tons",
            tableItem18: "430 million tons",
        },

        subtitle7: "China",
        mediumText7: "",
        paragraph7:
            "According to the International Energy Agency, China contributes about 3.7 billion tons of coal to the global market. It is an essential coal supplier, accounting for over 47% of the world's total production. According to studies, it is the world's largest consumer of coal. It consumes more than 53% of the world's total. In 2020, President Xi Jinping announced that China would work towards attaining carbon neutrality before 2060. As a result of this announcement, the country started reducing its dependence on coal for its local energy supply. However, the transition from a carbon-dependent energy source to a carbon-independent one can only happen after a period. The International Energy Agency predicts that the annual coal production rate will remain constant for at least two years before it begins to decline. ",
        articleImg7: "",

        subtitle8: "India ",
        mediumText8: "",
        paragraph8:
            "India contributes over 783 million tons to the world's total output. It has the second-highest coal production rate in the world. It accounts for about 10% of the world's total output. The country is home to the largest coal-producing company in the world, State-owner Coal India, which produces over 80% of the country's coal. The company possesses about 360 mines. The Indian government took a bold step in 2020 to reduce the country's dependence on foreign companies. Over 40 coal mines were opened to the private sector for development. Studies showed that the initial interest in these sales could have been higher due to insufficient investors from the private sector. However, in recent years, records show that the interest and competition from renewables have significantly increased. ",
        articleImg8: "",

        subtitle9: "United States ",
        mediumText9: "",
        paragraph9:
            "The United States contributes over 640 million tons of coal to the global market. While the nation's coal production rate experienced a severe decline in 2019, it remains one of the largest coal-producing countries in the world. Due to competition from cheaper renewables and natural gas, the coal demand rate in the country for energy supply has been reduced. The United States government continues to take vital steps to boost the demand rate in the country. ",
        articleImg9: "",

        subtitle10: "",
        mediumText10: "",
        paragraph10:
            "According to the International Energy Agency, the country’s total output was reduced to about 491 million tons in 2020. In 2021, the whole production increased to 539 million tons. According to the IEA, five cities in the United States contribute 71% of the country's total production. These cities include; Kentucky (5%), Illinois (6.5%), Pennsylvania (7%), West Virginia (13%), and Wyoming (39%). ",
        articleImg10: "",

        subtitle11: "Indonesia ",
        mediumText11: "",
        paragraph11:
            "According to the International Energy Agency, Indonesia accounts for over 616 million tons globally. It is one of the most significant thermal coal suppliers worldwide. Most of its trade occurs in India and China. The coronavirus pandemic caused a decrease in the coal demand rate in the country. The reduction in demand rate increased the price of domestic commodities and forced miners to reduce their production rate. Indonesia's total coal production was reduced to 529 million tons in 2020 and rose to 545 million tons in 2021. The government sets a yearly target for the coal-mining countries to maintain a productive coal output.",
        articleImg11: "",

        subtitle12: "Australia ",
        mediumText12: "",
        paragraph12:
            "With over 550 million tons supplied to the global market, Australia is one of the largest coal-mining countries in the world. Coal is the second most profitable export in the country, after concentrates and iron ores. Thermal coal and metallurgical coal account for half and one-third, respectively. While the annual production rate was reduced by 9% in 2020, the country still records a 3.4% increase yearly. ",
        articleImg12: "",

        subtitle13: "",
        mediumText13: "",
        paragraph13:
            "Australia is one of the most renowned suppliers of metallurgical coal globally. It makes most of its trade with China's steelmaking sector. According to the International Energy Agency, coal is mined consistently in every state in the country. However, New South Wales and Queensland are the most productive regions. ",
        articleImg13: "",

        subtitle14: "Russia",
        mediumText14: "",
        paragraph14:
            "Russia contributes over 430 million tons of coal to the global market. It is the sixth-largest coal supplier in the world. It accounts for around 5% of the world's output. According to the International Energy Agency, the country experienced an 8% decline in coal production. This decline was due to a significant fall in demand locally and in major international markets, including Korea and Europe. ",
        articleImg14: "",

        subtitle15: "",
        mediumText15: "",
        paragraph15:
            "Russia is home to the second-largest coal reserves in the world. It has basins in Siberia that contribute as much as 162 tons to the country's resources. Lawmakers continue to create laws to improve domestic coal production and put the country at the forefront of the global market. ",
        articleImg15: "",

        subtitle16: "Conclusion ",
        mediumText16: "",
        paragraph16:
            "Coal is one of the most critical thermal energy sources in the world. Over the years, the demand for manufacturing processes has increased in different parts of the world. The countries listed above contribute significantly to coal production for sustainable industrialization and manufacturing processes.",
        articleImg16: "",

        subtitle17: "",
        mediumText17: "",
        paragraph17: "",
        articleImg17: "",

        subtitle18: "",
        mediumText18: "",
        paragraph18: "",
        articleImg18: "",

        subtitle19: "",
        mediumText19: "",
        paragraph19: "",
        articleImg19: "",

        subtitle20: "",
        mediumText20: "",
        paragraph20: "",
        articleImg20: "",

        subtitle21: "",
        mediumText21: "",
        paragraph21: "",
        articleImg21: "",
    },
    {
        id: 5,
        preview: "images/article-preview-5.webp",
        tag: "top countries",
        name: "The Top coal mining leaders in Russia and the key buyers for 2022-2023",
        paragraph1:
            "From the snow-capped mountains of Siberia to the sprawling open-pit mines in Kuzbass, Russia's coal mining industry has been a vital contributor to the country's economy for decades. As one of the world's largest producers of coal, Russia is responsible for a significant portion of the global supply chain. However, with increasing competition from renewable energy sources, growing concerns over climate change and Russia’s ongoing war with Ukraine, the  industry faces a number of challenges in the years ahead.",

        subtitle2: "",
        mediumText2: "",
        paragraph2:
            "In this article, we will explore the top coal mining leaders in Russia, key buyers of Russian coal, and the future outlook for the industry.",
        articleImg2: "",

        subtitle3: "Top Coal Mining Leaders in Russia",
        mediumText3: "",
        paragraph3:
            "Russia's coal mining industry is dominated by several large companies that control a significant portion of the market. These companies are responsible for the production, processing, and distribution of coal throughout the country and beyond. Here are four of the top coal mining leaders in Russia:",
        articleImg3: "",

        subtitle4: "",
        mediumText4: "Siberian Coal Energy Company (SUEK)",
        paragraph4:
            "SUEK is one of the largest coal mining companies in Russia and the world. The company operates coal mines and processing plants in Siberia and the Far East, producing high-quality coal for both domestic and international markets. SUEK also owns power plants and railway infrastructure, making it a vertically integrated company that can control the entire supply chain.",
        articleImg4: "",

        subtitle5: "",
        mediumText5: "Kuzbassrazrezugol (KRU)",
        paragraph5:
            "KRU is a major coal mining company in Russia, operating in the Kemerovo region in Western Siberia. The company operates several large open-pit coal mines and a processing plant, producing a variety of coal grades for different markets. KRU is known for its efficient and environmentally responsible mining practices, which have helped it to become a leader in the industry.",
        articleImg5: "",

        subtitle6: "",
        mediumText6: "SDS-Ugol",
        paragraph6:
            "SDS-Ugol is another major coal mining company in Russia, with operations in the Kuzbass region of Western Siberia. The company produces high-quality thermal coal for domestic and international markets, with a focus on energy efficiency and environmental sustainability. SDS-Ugol is also involved in coal transportation and logistics, ensuring that its products can reach customers efficiently and cost-effectively.",
        articleImg6: "",

        subtitle7: "",
        mediumText7: "Russian Coal",
        paragraph7:
            "Russian Coal is a privately owned coal mining company with operations in several regions of Russia. The company produces thermal and coking coal for domestic and export markets, with a focus on quality and customer service. Russian Coal is also involved in coal trading and logistics, making it a one-stop-shop for customers looking to purchase Russian coal.",
        articleImg7: "",

        subtitle8: "",
        mediumText8: "",
        paragraph8:
            "These four companies are at the forefront of Russia's coal mining industry, driving innovation and growth while maintaining a commitment to sustainability and efficiency. Other companies worthy of note include VostSibUgol, RUK (Novokuznetsk), Southern Kuzbass (Yuzhny Kuzbass, asset of Mechel), Vorkutaugol (part of Severstal Group), Kuzbasskaya Toplivnaya Company (KTK) and RUK (Mezhdurechevsk)",
        articleImg8: "",

        subtitle9: "Key Buyers of Russian Coal",
        mediumText9: "",
        paragraph9:
            "Russia's coal industry plays a crucial role in the country's economy, contributing to more than 15% of its GDP. In recent years, however, political tensions between Russia and Ukraine have had a significant impact on the Russian fossil fuels industry. Despite this, the country remains a major supplier of coal to a number of key buyers. These major buyers include",
        articleImg9: "",

        subtitle10: "",
        mediumText10: "China",
        paragraph10: "",
        articleImg10: "/images/article-5-img-1.webp",

        subtitle11: "",
        mediumText11: "",
        paragraph11:
            "China is the world's largest coal consumer, and it has a significant demand for high-quality coal to fuel its industries. Russia is one of the top suppliers of coal to China, with shipments reaching over 31 million metric tons in 2020. The two countries have signed a series of agreements to increase coal exports to China, and Russia is expected to continue to play a critical role in meeting China's energy demands in the future.",
        articleImg11: "",

        subtitle12: "",
        mediumText12: "Japan",
        paragraph12: "",
        articleImg12: "",

        subtitle13: "",
        mediumText13: "",
        paragraph13:
            "Japan is another significant buyer of Russian coal, with imports reaching over 18 million metric tons in 2020. The country's reliance on imported energy has increased since the Fukushima nuclear disaster in 2011. Russia has become a reliable supplier of coal to Japan, with its proximity to the country providing a strategic advantage.",
        articleImg13: "",

        subtitle14: "",
        mediumText14: "South Korea",
        paragraph14: "",
        articleImg14: "",

        subtitle15: "",
        mediumText15: "",
        paragraph15:
            "South Korea is a significant importer of fossil fuels, including coal, with imports reaching over 10 million metric tons in 2020. The country's energy mix is heavily reliant on imported coal, and Russia has become a reliable supplier in recent years. The two countries have signed a series of agreements to increase coal exports, and South Korea is expected to continue to rely on Russian coal in the future.",
        articleImg15: "",

        subtitle16: "",
        mediumText16: "Turkey",
        paragraph16: "",
        articleImg16: "",

        subtitle17: "",
        mediumText17: "",
        paragraph17:
            "Turkey is one of the fastest-growing economies in the world, and its energy demands are increasing rapidly. The country is one of the top buyers of Russian coal, with imports reaching over 8 million metric tons in 2020. Russia's proximity to Turkey and its large coal reserves make it an attractive supplier for the country's energy needs.",
        articleImg17: "",

        subtitle18: "",
        mediumText18: "",
        paragraph18:
            "While these buyers are still actively buying, the imposition of economic sanctions on Russia by the United States and European Union has made it more difficult for Russian coal producers to access key markets. The sanctions have also limited the ability of Russian companies to obtain financing and access technology, and imposed restrictions on the sale of certain types of equipment to Russia. This has had a significant impact on the industry, and has forced many Russian companies to seek new markets for their coal.",
        articleImg18: "",

        subtitle19: "The Future of Russia's Coal Mining Industry",
        mediumText19: "",
        paragraph19:
            "Sanctions from Western countries and declining demand have impacted the industry's growth. However, there are still opportunities for growth in the coming years, especially in the Asian markets. ",
        articleImg19: "",

        subtitle20: "",
        mediumText20: "",
        paragraph20:
            "According to Statista, China and Japan are the top importers of Russian coal, followed by South Korea and Taiwan. These countries are expected to continue to be key buyers of Russian coal in 2022-2023. In addition, Russia is also exploring new export routes, including the Northern Sea Route, which could potentially boost the country's coal exports.",
        articleImg20: "",

        subtitle21: "",
        mediumText21: "",
        paragraph21:
            "Now, the future of the industry depends on how well leaders can capitalize on these opportunities and adapt to alternative energy sources. So despite the uncertainties, the coal mining leaders in Russia have the potential to remain significant players in the global energy sector.",
        articleImg21: "",
    },
    {
        id: 6,
        preview: "images/article-preview-6.webp",
        tag: "top companies",
        name: "World leaders in the production of coal mining equipment and machinery",
        paragraph1:
            "Mining has always been a vital industry, and the coal mining sector is no exception. Coal provides an essential source of energy for industries and homes worldwide, and the machinery and equipment used to extract it are just as crucial. ",

        subtitle2: "",
        mediumText2: "",
        paragraph2:
            "The world of coal mining equipment and machinery is a complex and fascinating one, filled with cutting-edge technology and state-of-the-art engineering. From massive draglines that can move millions of tons of earth to underground continuous miners that operate in cramped spaces, the equipment and machinery used in coal mining are as varied as they are impressive. ",
        articleImg2: "",

        subtitle3: "",
        mediumText3: "",
        paragraph3:
            "In this article, we will explore the front runners in the production of coal mining equipment and machinery. ",
        articleImg3: "",

        table3: {
            tableHeader1: "Name of Company",
            tableHeader2: "Country",
            tableItem1: "Caterpillar Inc.",
            tableItem2: "Komatsu Ltd.",
            tableItem3: "Sandvik AB",
            tableItem4: "Epiroc AB",
            tableItem5: "Joy Global Inc.",
            tableItem6: "Hitachi Construction Machinery Co., Ltd.",
            tableItem7: "Metso Outotec Corporation ",
            tableItem8: "Liebherr-International AG ",
            tableItem9: "Zhengzhou Coal Mining Machinery Group Co., Ltd.",
            tableItem10:
                "Sany Heavy Equipment International Holdings Co. Ltd. ",
            tableItem11: "United states ",
            tableItem12: "Japan ",
            tableItem13: "Sweden ",
            tableItem14: "Sweden ",
            tableItem15: "United states",
            tableItem16: "Japan ",
            tableItem17: "Finland ",
            tableItem18: "Switzerland ",
            tableItem19: "China",
            tableItem20: "China ",
        },

        subtitle4: "Caterpillar Inc. (United States) ",
        mediumText4: "",
        paragraph4: "",
        articleImg4: "",

        subtitle5: "",
        mediumText5: "",
        paragraph5:
            "Caterpillar Inc. is a global leader in the production of coal mining equipment and machinery, with a history that dates back over 90 years. The company offers a wide range of products designed for the mining industry, including draglines, electric rope shovels, mining trucks, and more. ",
        articleImg5: "",

        subtitle6: "",
        mediumText6: "",
        paragraph6:
            "Caterpillar is known for its commitment to safety and efficiency, with a focus on developing innovative products that can help customers reduce costs and improve productivity. The company has a strong global presence, with operations in more than 190 countries.",
        articleImg6: "",

        subtitle7: "Komatsu Ltd. (Japan) ",
        mediumText7: "",
        paragraph7: "",
        articleImg7: "",

        subtitle8: "",
        mediumText8: "",
        paragraph8:
            "Komatsu Ltd. is a leading Japanese multinational corporation that specializes in the production of construction, mining, and military equipment. The company is a major producer of coal mining equipment and machinery, including hydraulic excavators, bulldozers, dump trucks, and more. ",
        articleImg8: "",

        subtitle9: "",
        mediumText9: "",
        paragraph9:
            "With a focus on innovation, technology, and automation, Komatsu aims to improve safety, efficiency, and productivity in the mining industry. The company also places a strong emphasis on sustainability and environmental responsibility, working to reduce the impact of its operations on the planet.",
        articleImg9: "",

        subtitle10: "Sandvik AB (Sweden)",
        mediumText10: "",
        paragraph10:
            "Sandvik AB is a Swedish engineering company that produces a wide range of products, including mining equipment and machinery. The company's coal mining equipment and machinery products are known for their high quality and reliability. ",
        articleImg10: "",

        subtitle11: "",
        mediumText11: "",
        paragraph11:
            "Sandvik AB is committed to sustainable and responsible manufacturing practices, with a focus on reducing its environmental impact. The company invests heavily in research and development to create innovative products that meet the needs of its customers. Sandvik AB is also known for its excellent customer service, providing support and training to customers around the world.",
        articleImg11: "",

        subtitle12: "Epiroc AB (Sweden) ",
        mediumText12: "",
        paragraph12:
            "Epiroc AB (Sweden) - Epiroc AB is a Swedish manufacturer of mining and infrastructure equipment, including coal mining equipment and machinery. The company was formed in 2018 as a spin-off from Atlas Copco, and has quickly become a leading player in the industry. ",
        articleImg12: "",
        subtitle13: "",
        mediumText13: "",
        paragraph13:
            "Epiroc is known for its innovative products and services, including its SmartROC range of drilling rigs which use advanced automation technology to improve safety and efficiency. The company is also committed to sustainability, with a focus on reducing energy consumption and carbon emissions in its operations.",
        articleImg13: "/images/article-6-img-1.webp",

        subtitle14: "Joy Global Inc. (United States)",
        mediumText14: "",
        paragraph14:
            "Joy Global Inc. is a leading producer of coal mining equipment and machinery, with a focus on safety and productivity. The company offers a range of products, including underground mining machinery, surface mining equipment, and more. ",
        articleImg14: "",

        subtitle15: "",
        mediumText15: "",
        paragraph15:
            "Joy Global is committed to using advanced technology and automation to improve safety and efficiency, and offers a range of products designed to help mining companies maximize their productivity while reducing their environmental impact. The company has a global presence, with operations in more than 20 countries around the world.",
        articleImg15: "",

        subtitle16: "Hitachi Construction Machinery Co., Ltd. (Japan)",
        mediumText16: "",
        paragraph16:
            "Hitachi Construction Machinery Co. Ltd. is a Japanese multinational corporation that specializes in the production of construction and mining equipment, including coal mining equipment and machinery. The company offers a wide range of products designed to improve safety and productivity, with a focus on technological innovation and sustainability.",
        articleImg16: "",

        subtitle17: "",
        mediumText17: "",
        paragraph17:
            "Hitachi Construction Machinery Co. Ltd. is known for its reliable and durable equipment, with a global presence in over 150 countries. The company has a commitment to environmental responsibility and offers products that minimize the impact of mining operations on the environment.",
        articleImg17: "",

        subtitle18: "Metso Outotec Corporation (Finland)",
        mediumText18: "",
        paragraph18:
            "Metso Outotec Corporation is a Finnish multinational company that produces a range of mining and mineral processing equipment, including coal mining equipment and machinery. The company was formed in 2020 through a merger between Metso Corporation and Outotec Oyj, and has a global presence with operations in more than 50 countries. ",
        articleImg18: "",

        subtitle19: "",
        mediumText19: "",
        paragraph19:
            "Metso Outotec is committed to sustainability and reducing its environmental impact, and offers a range of products designed to improve efficiency and productivity, including grinding mills, flotation machines, and more. The company also offers services and solutions to optimize its customers' operations.",
        articleImg19: "",

        subtitle20: "Liebherr-International AG (Switzerland) ",
        mediumText20: "",
        paragraph20:
            "Liebherr-International AG is a Swiss multinational company that has a strong presence in the mining industry, including coal mining equipment and machinery. The company's products include excavators, haul trucks, and loaders, all of which are designed with a focus on safety, efficiency, and sustainability.",
        articleImg20: "",

        subtitle21: "",
        mediumText21: "",
        paragraph21:
            "Liebherr's mining equipment is known for its reliability and durability, and the company has a reputation for developing innovative solutions to meet the changing needs of the industry. With operations around the world, Liebherr is a major player in the coal mining equipment and machinery market.",
        articleImg21: "",

        subtitle22: "Zhengzhou Coal Mining Machinery Group Co., Ltd. (China) ",
        mediumText22: "",
        paragraph22:
            "Zhengzhou Coal Mining Machinery Group Co., Ltd. is a Chinese company that specializes in the design, manufacturing, and distribution of coal mining machinery and related parts. The company's products include a range of equipment for the coal mining industry, such as hydraulic support systems, armored face conveyors, and coal shearers. ",
        articleImg22: "",

        subtitle23: "",
        mediumText23: "",
        paragraph23:
            "With a focus on technological innovation, the company aims to improve efficiency, productivity, and safety in the coal mining industry. It has a strong presence in the Chinese market, and has expanded its operations globally to meet the growing demand for coal mining equipment and machinery.",
        articleImg23: "",

        subtitle24:
            "Sany Heavy Equipment International Holdings Co. Ltd. (China) ",
        mediumText24: "",
        paragraph24:
            "Sany Heavy Equipment International Holdings Co., Ltd. is a Chinese manufacturer of heavy equipment, including coal mining equipment and machinery. The company has a global presence, with exports to more than 150 countries. It is committed to providing innovative and sustainable solutions to its customers. ",
        articleImg24: "",

        subtitle25: "",
        mediumText25: "",
        paragraph25:
            "Sany Heavy Equipment International Holdings Co., Ltd. offers a range of products designed to improve safety and productivity, with a focus on quality and reliability. The company also provides excellent after-sales service and support to its customers around the world.",
        articleImg25: "",

        subtitle26: "Bottomline ",
        mediumText26: "",
        paragraph26:
            "The coal mining equipment and machinery industry is expected to witness steady growth in the coming years due to the increasing demand for coal in various industries. However, the industry also faces challenges such as environmental concerns and the rise of alternative energy sources. ",
        articleImg26: "",

        subtitle27: "",
        mediumText27: "",
        paragraph27:
            "The development of advanced technologies such as automation and AI could potentially address these challenges and drive further growth in the industry. As it stands, the future of coal mining equipment and machinery remains dynamic and exciting.",
        articleImg27: "",
    },
    {
        id: 7,
        preview: "images/article-preview-7.webp",
        tag: "top companies",

        name: "Leading exporters of coal in the world",
        paragraph1:
            "The leading exporter of coal in the world is Australia. Close to 36% of total coal export in the world comes from Australia. Other countries like Russia, Indonesia, South Africa and the United States of America are also leading exporters. These countries, together with Australia, are responsible for about 85% of total coal exports in the world. Despite being the leading exporter of coal, Australia is not the largest producer of coal. ",

        subtitle2: "",
        mediumText2: "",
        paragraph2:
            "Exportation of coal is a major source of income. From 2020 to 2021, the cost of exportation of coal increased by 48%. In 2021, about $123 billion was the cost of coal exported all over the world. This comes with a lot of profits. ",
        articleImg2: "",

        subtitle3: "",
        mediumText3: "",
        paragraph3:
            "The leading exporters of coal, together with the value of coal exported in 2021 are described in the table below",
        articleImg3: "",

        tableLarge3: {
            tableHeader1: "Country",
            tableHeader2: "Worth Of Coal Exported",
            tableHeader3: "Percentage Of Coal Exported",
            tableItem1: "Australia",
            tableItem2: "Indonesia",
            tableItem3: "Russia",
            tableItem4: "United States of America",
            tableItem5: "South Africa",
            tableItem6: "Canada",
            tableItem7: "Colombia",
            tableItem8: "Mongolia",
            tableItem9: "Mozambique",
            tableItem10: "Kazakhstan",
            tableItem11: "$44 Billion",
            tableItem12: "$26.5 Billion",
            tableItem13: "$17.7 Billion",
            tableItem14: "$9.6 Billion",
            tableItem15: "$6 Billion",
            tableItem16: "$6 Billion",
            tableItem17: "$4.5 Billion",
            tableItem18: "$1.9 Billion",
            tableItem19: "$1.1 Billion",
            tableItem20: "$930 Million",
            tableItem21: "35.6%",
            tableItem22: "21.7%",
            tableItem23: "14.5%",
            tableItem24: "7.7%",
            tableItem25: "4.8%",
            tableItem26: "4.8%",
            tableItem27: "3.6%",
            tableItem28: "1.7%",
            tableItem29: "1%",
            tableItem30: "0.9%",
        },

        subtitle4: "",
        mediumText4: "",
        paragraph4:
            "Other countries like Vietnam, Poland and Philippines account for a very little percentage of the world's total export. Some of these countries, in recent years, have become great exporters of coal compared to what they used to be. An example is Kazakhstan, who in 2021,  exported about 170% of what they did in 2020. ",
        articleImg4: "",

        subtitle5: "Top Producers Of Coal In The World",
        mediumText5: "",
        paragraph5:
            "China and India are the leading producers of coal in the world. These two countries are however not great exporters of coal, because most of the coal produced are used to generate electricity. China is the largest consumer of coal in the world, followed by India.  Only little or no percentage is available for exportation in these two countries. ",
        articleImg5: "",

        subtitle6: "",
        mediumText6: "",
        paragraph6:
            "Other countries like the United States of America, Australia and Indonesia, are also great producers of coal. These countries produce more coal than they actually need. The excess coal produced is then exported to countries who demand it. ",
        articleImg6: "",

        subtitle7: "",
        mediumText7: "",
        paragraph7:
            "China and India, who are the leading producers of coal, are large importers of coal. Due to their consumption of coal for the generation of electricity, their need for coal rises every year. They therefore rely heavily on countries like Indonesia for their coal import despite being the best producers of coal in the world. ",
        articleImg7: "",

        subtitle8: "Exportation Of Coal In Australia",
        mediumText8: "",
        paragraph8:
            "Australia leads the world in the exportation of coal. Of the total coal exported all over the world, Australia is responsible for about 36% as at 2021. The country is a great producer of coal but a poor consumer. Australia uses only about 15 to 16 percent of the total coal produced while the remaining are available for exportation. ",
        articleImg8: "",

        subtitle9: "",
        mediumText9: "",
        paragraph9:
            "Over the last few years, Australia has increased its production of coal for the sake of exportation to countries who demand it. Because of its increased production, Australia has the third largest coal reserve in the world. Japan receives most of its coal imports from Australia.",
        articleImg9: "",

        subtitle10: "Exportation Of Coal In The United States",
        mediumText10: "",
        paragraph10:
            "The United States of America has the largest coal reserve in the world. They are great producers of coal and only a little percentage of it is used to generate electricity. The United States exports both steam coal and metallurgical coal. The U.S is responsible for 7.7% of total coal exports in the world. The United States exports majorly to India, China, Japan and the Netherlands. ",
        articleImg10: "",

        subtitle11: "",
        mediumText11: "",
        paragraph11:
            "Between 2012 and 2016, the exportation of coal in the United States dropped by a large percentage.  This occurred because the country wasn't producing much. From 2017, the production of coal rose followed by a decrease in the consumption of coal. This led to an exponential increase in the exportation of coal. ",
        articleImg11: "",

        subtitle12: "Exportation Of Coal In Indonesia",
        mediumText12: "",
        paragraph12:
            "Indonesia is the second largest exporter of coal in the world after Australia. This country accounts for 21.7 percent of the total coal exported all over the world. The exportation of coal in Indonesia dropped by 12.4% in 2020. However, they are great producers of coal too and consume only about 20% of it. Indonesia is responsible for about 4% of total coal reserves in the world. ",
        articleImg12: "",

        subtitle13: "",
        mediumText13: "",
        paragraph13: "China receives most of its coal imports from Indonesia.",
        articleImg13: "",

        subtitle14: "Exportation Of Coal In Russia",
        mediumText14: "",
        paragraph14:
            "Russia has the second largest coal reserve in the world after the United States of America. They mainly produce hard coal. Russia accounts for 14.5% of the total coal exports in the world and are great exporters of coal. They export over 50% of the total coal produced in the country while the rest are used domestically to generate electricity. ",
        articleImg14: "",

        subtitle15: "",
        mediumText15: "",
        paragraph15:
            "Most of the coal coming from Russia is exported mainly to China and the European Union.  ",
        articleImg15: "",

        subtitle16: "Exportation Of Coal In South Africa",
        mediumText16: "",
        paragraph16:
            "South Africa is also one of the biggest exporters of coal in the world. About 90% of the total coal used by African countries comes from South Africa. Over 50% of electricity generated in South Africa comes from the consumption of coal. South Africa is responsible for about 4.8% of the total coal exports in the world.",
        articleImg16: "",

        subtitle17: "Top Coal Exporting Companies In The World",
        mediumText17: "",
        paragraph17:
            "Some of the best coal exporting companies in the world include:",
        articleImg17: "",

        list17: {
            listItem1: "● Bumi Resources, Indonesia",
            listItem2: "● Siberian Coal Energy Company, Russia",
            listItem3: "● BHP Billiton Mitsubishi Alliance, Australia",
            listItem4: "● Exxaro Resources, South Africa",
        },

        subtitle18: "Conclusion",
        mediumText18: "",
        paragraph18:
            "The largest exporters of coal in the world are Australia, Indonesia, Russia, United States Of America and South Africa. They are also large producers of coal but the biggest producers of coal in the world are China and India. China and India consume most of the coal they produce and barely export coal. These exporters of coal are also consumers but they are poor consumers. Poor consumption leaves them with excess which they export to countries in need. Some countries like Australia even produce more to meet the demand in the market.",
        articleImg18: "",
    },
    {
        id: 8,
        preview: "images/article-preview-8.webp",
        tag: "world coal",
        name: "Fueling the World: Indonesia's Coal Industry and Its Major Buyers",
        paragraph1:
            "Indonesia is a major global supplier of coal that has fueled the economic growth of many nations around the world. Indonesia's vast coal reserves, coupled with its strategic location and competitive prices, have made it a preferred supplier of coal for many countries, particularly in Asia.",
        subtitle2: "",
        mediumText2: "",
        paragraph2:
            "In this article, we will take a closer look at Indonesia's coal industry, its history, major buyers, and the future opportunities and challenges that lie ahead.",
        articleImg2: "",

        subtitle3: "The Rise of Indonesia's Coal Industry: A Brief History",
        mediumText3: "",
        paragraph3:
            "Indonesia's coal industry has seen significant growth over the years, making it one of the largest coal producers and exporters in the world. The history of Indonesia's coal industry dates back to the early 18th century, where coal was first discovered in the region of Ombilin, West Sumatra. However, it wasn't until the 1980s that the industry began to take off and experience rapid growth.",
        articleImg3: "",

        subtitle4: "",
        mediumText4: "",
        paragraph4:
            "During the 1980s, the Indonesian government opened up the coal mining sector to foreign investors, which led to a surge in foreign investment in the industry. By the 1990s, Indonesia's coal production had surpassed that of Australia and the United States, making it the world's largest exporter ofgy thermal coal.",
        articleImg4: "",

        subtitle5: "",
        mediumText5: "",
        paragraph5:
            "The demand for Indonesian coal continued to increase throughout the 2000s, fueled by China's rapid economic growth and its need for coal to power its industries. This led to a further expansion of Indonesia's coal industry, with new mines and infrastructure being developed to meet the growing demand for coal.",
        articleImg5: "",

        subtitle6: "",
        mediumText6: "",
        paragraph6:
            "Today, Indonesia's coal industry remains a key contributor to the country's economy, with coal exports accounting for a significant portion of the country's total export earnings.",
        articleImg6: "",

        subtitle7: "Types of Coal in Indonesia ",
        mediumText7: "",
        paragraph7:
            "There are several types of coal found in Indonesia and each possesses its own unique properties and characteristics. ",
        articleImg7: "",

        table7: {
            tableHeader1: "Type of coal",
            tableHeader2: "Carbon content",
            tableItem1: "Lignite coal",
            tableItem2: "Sub-bituminous coal",
            tableItem3: "Bituminous coal",
            tableItem4: "Anthracite coal",
            tableItem5: "<60%",
            tableItem6: "60-70%",
            tableItem7: "70-86%",
            tableItem8: ">86%",
        },

        subtitle8: "",
        mediumText8: "● Lignite coal",
        paragraph8:
            "This coal is also known as brown coal. Lignite coal is the lowest rank of coal, with a carbon content of less than 60%. It has a high moisture content and a low heating value, making it less efficient than other types of coal.",
        articleImg8: "",

        subtitle9: "",
        mediumText9: "● Sub-bituminous coal ",
        paragraph9:
            "Sub-bituminous coal has a slightly higher carbon content than lignite coal, ranging between 60% and 70%. It has a lower moisture content and a higher heating value, making it a more efficient source of energy than lignite coal. It is also known as black lignite",
        articleImg9: "",

        subtitle10: "",
        mediumText10: "● Bituminous coal ",
        paragraph10:
            "This is the most common type of coal used for electricity generation worldwide. Bituminous coal has a carbon content ranging from 70% to 86%, with a relatively low moisture content and a high heating value.",
        articleImg10: "",

        subtitle11: "",
        mediumText11: "● Anthracite coal",
        paragraph11:
            "This is the highest rank of coal and the rarest type found in the country. Anthracite coal has a carbon content of over 86%, making it extremely hard and dense. It has the highest heating value of all the types of coal found in Indonesia, making it a valuable source of energy for industrial applications.",
        articleImg11: "",

        subtitle12: "Major Buyers of Indonesian Coal: Who's Fueling Up?",
        mediumText12: "",
        paragraph12:
            "Indonesian coal exports primarily go to Asian countries. In 2021, China, India, Japan and South Korea among others had a large share of the coal.",
        articleImg12: "",

        table12: {
            tableHeader1: "Country",
            tableHeader2: "Percentage",
            tableItem1: "China",
            tableItem2: "India",
            tableItem3: "Japan",
            tableItem4: "South Korea",
            tableItem5: "Other countries",
            tableItem6: "37%",
            tableItem7: "20%",
            tableItem8: "13%",
            tableItem9: "10%",
            tableItem10: "20%",
        },

        subtitle13: "",
        mediumText13: "China - 37%",
        paragraph13:
            "China is the largest buyer of Indonesian coal, accounting for 37% of the country's coal exports in 2021. Now, the Chinese government is set to kickstart a post COVID recovery for its economy. Hence its requirements for more coal imports. Although the country has invested on domestic coal production, its demand for Indonesian coal is driven by its growing energy needs.",
        articleImg13: "",

        subtitle14: "",
        mediumText14: "India - 20%",
        paragraph14:
            "India is the second-largest buyer of Indonesian coal, accounting for 20% of the country's coal exports. The country's power sector relies heavily on coal, and its demand for coal is expected to continue to grow in the coming years. Indian companies have also invested in Indonesian coal mines to secure their supply of coal.",
        articleImg14: "",

        subtitle15: "",
        mediumText15: "Japan - 13%",
        paragraph15:
            "Japan is the third-largest buyer of Indonesian coal, accounting for 13% of the country's coal exports. The country's dependence on coal for electricity generation has increased since the Fukushima nuclear disaster in 2011. Japan has been importing more coal to replace the electricity that was previously generated by nuclear power.",
        articleImg15: "",

        subtitle16: "",
        mediumText16: "South Korea - 10%",
        paragraph16:
            "South Korea is the fourth-largest buyer of Indonesian coal, accounting for 10% of the country's coal exports. The country's energy mix includes coal, natural gas, and nuclear power. The South Korean government has recently announced plans to phase out nuclear power and increase its reliance on natural gas and renewable energy sources.",
        articleImg16: "",

        subtitle17: "",
        mediumText17: "",
        paragraph17:
            "Other buyers of Indonesian coal include Taiwan, Malaysia, Vietnam, and Thailand. These countries account for a smaller percentage of Indonesia's coal exports compared to the top four buyers.",
        articleImg17: "",

        subtitle18: "Future of Indonesia's Coal Industry",
        mediumText18: "",
        paragraph18:
            "The global demand for coal has been decreasing due to the rise of renewable energy sources and a growing awareness of the negative impact of coal on the environment. This has put pressure on coal-producing countries like Indonesia to shift towards more sustainable alternatives.",
        articleImg18: "",

        subtitle19: "",
        mediumText19: "",
        paragraph19:
            "In response to these challenges, Indonesia has taken steps to diversify its energy mix and reduce its dependence on coal. The government has set a target of generating 23% of the country's energy from renewable sources by 2025, up from just 9% in 2019. This includes the development of solar, wind, and hydropower projects, as well as investments in energy storage and transmission infrastructure.",
        articleImg19: "",

        subtitle20: "",
        mediumText20: "",
        paragraph20:
            "However, despite these efforts, the future of Indonesia's coal industry remains uncertain. The country still has abundant coal reserves and the industry provides jobs and revenue for many communities. In addition, some countries, particularly in Asia, continue to rely heavily on coal for their energy needs.",
        articleImg20: "",

        subtitle21: "",
        mediumText21: "",
        paragraph21:
            "One potential scenario for the future of Indonesia's coal industry is a gradual transition towards cleaner and more efficient coal technologies, such as ultra-supercritical coal-fired power plants. This would allow Indonesia to continue exporting coal to countries that are not yet ready to transition away from coal, while also reducing the environmental impact of the industry.",
        articleImg21: "",

        subtitle22: "Bottomline",
        mediumText22: "",
        paragraph22:
            "Despite the continued high demand for coal, there is a noticeable shift towards renewable sources of energy. This shift could potentially lead to a decrease in the number of customers who are interested in purchasing coal from Indonesia. Although this may have a negative impact on Indonesia's earnings, it could also encourage the country and its coal consumers to transition towards a more environmentally sustainable energy future.",
        articleImg22: "",
    },
    {
        id: 9,
        preview: "images/article-preview-9.webp",
        tag: "top countries",
        name: "World coal reserves, ranking of countries",
        paragraph1:
            "Only ten countries contain more than 90% of the proven coal reserves in the entire world. Even though China is the largest producer and user of coal, they come in third on the list. The US leads the list with more than one-fifth of all proven coal reserves. Based on total proved reserves, here’s a list of top seven countries for coal reserves.",

        subtitle2: "",
        mediumText2: "",
        paragraph2: "",

        subtitle3: "",
        mediumText3: "Proven Coal Reserves Ranking by Country as of 2023",
        paragraph3: "",
        articleImg3: "",

        tableLarge3: {
            tableHeader1: "S/N",
            tableHeader2: "Countries",
            tableHeader3: "Data",
            tableItem1: "1",
            tableItem2: "2",
            tableItem3: "3",
            tableItem4: "4",
            tableItem5: "5",
            tableItem6: "6",
            tableItem7: "7",
            tableItem11: "United States of America",
            tableItem12: "Russia",
            tableItem13: "Australia",
            tableItem14: "China",
            tableItem15: "India",
            tableItem16: "Indonesia",
            tableItem17: "Germany",
            tableItem21: "228,662,000,000 Cu.M",
            tableItem22: "162,166,000,000 Cu.M",
            tableItem23: "149,079,000,000 Cu.M",
            tableItem24: "141,595,000,000 Cu.M",
            tableItem25: "105,931,000,000 Cu.M",
            tableItem26: "39,891,000,000 Cu.M",
            tableItem27: "35,900,000,000 Cu.M",
        },

        subtitle4: "1. United States of America",
        mediumText4: "",
        paragraph4:
            "World coal reserves are primarily located in the United States. The country had 250.2 billion tonnes (Bt) of proven coal reserves as of December 2018, which is equivalent to almost 24% of the world's proven coal reserves. ",
        articleImg4: "",

        subtitle5: "",
        mediumText5: "",
        paragraph5:
            "The US has a large geographic distribution of coal reserves, with more than three-fourths of the total being located in Montana, Wyoming, Illinois, West Virginia, Kentucky, and Pennsylvania. According to reserves, the largest coal mine in the world is North Antelope Rochelle, run by Peabody Energy in Wyoming's Powder River Basin.",
        articleImg5: "",

        subtitle6: "",
        mediumText6: "",
        paragraph6:
            "The US ranks third in both coal production and consumption worldwide. In 2018, it produced 685 million tonnes (Mt), or roughly 9.3%, of the world's coal. In the same year, the nation accounted for 8.4% of all coal consumption worldwide. About 27% of the nation's electricity is produced from coal.",
        articleImg6: "",

        subtitle7: "2. Russia",
        mediumText7: "",
        paragraph7:
            "As of December 2018, the Russian Federation had 15.2% of the world's proven coal reserves.",
        articleImg7: "",

        subtitle8: "",
        mediumText8: "",
        paragraph8:
            "Major deposits in Russia can be found in the Kuznetski, Kansk-Achinsk, Irkutsk, and South Yakutsk basins in Eastern Russia as well as the Donetskii reserves in Moscow, the Pechora basins in Western Russia, and the Donetskii basins in Moscow. The main hard coal reserves are in the Pechora and Kuznetsk basins, and more than two thirds of the coal produced in Russia is hard coal. Large sub-bituminous coal resources are found in the Kansk-Achinsk Basin, and Russia's largest coal mine is the Raspadskaya in the Kemerovo region. ",
        articleImg8: "",

        subtitle9: "",
        mediumText9: "",
        paragraph9:
            "The world's sixth-largest producer and user of coal is Russia. In 2018, it produced 420Mt of coal and used 88Mt of coal with an equivalent amount of oil. Russia also ranks as the third biggest coal expo in the world. ",
        articleImg9: "",

        subtitle10: "3. Australia",
        mediumText10: "",
        paragraph10:
            "Over 14% of the world's total proven coal reserves are found in Australia, where they amount 147.4Bt. ",
        articleImg10: "",

        subtitle11: "",
        mediumText11: "",
        paragraph11:
            "The majority of the nation's black coal reserves are concentrated in New South Wales and Queensland, which together produce more than 95% of all the black coal produced in Australia. Most of Australia's reserves of brown coal are located in Victoria. The largest Australian coal mine by reserve is Peak Downs, located in central Queensland's Bowen Basin, followed by Mt Arthur, located in New South Wales' Hunter Valley. ",
        articleImg11: "",

        subtitle12: "",
        mediumText12: "",
        paragraph12:
            "Australia produces coal at the fifth-highest rate in the world. In 2018, it produced 483 Mt of coal (7.7% of the world's total). Australia ranks as the world’s second biggest coal exporter, this is because about 79% of its coal output is exported. ",
        articleImg12: "",

        subtitle13: "4. China",
        mediumText13: "",
        paragraph13:
            "13% of the world's proven coal reserves are in China. North and north-western China contain more than 70% of the world's proven recoverable coal reserves. ",
        articleImg13: "",

        subtitle14: "",
        mediumText14: "",
        paragraph14:
            "A significant portion of the country's accessible coal reserves are found in the provinces of Shanxi and Inner Mongolia. According to reserves, the Haerwusu coal mine in Inner Mongolia is the second largest coal mine in the world. ",
        articleImg14: "",

        subtitle15: "",
        mediumText15: "",
        paragraph15:
            "As for coal production and consumption, China leads the globe in both categories. In China, coal is the primary fuel used to produce energy.",
        articleImg15: "",

        subtitle16: "5. India",
        mediumText16: "",
        paragraph16:
            "At the end of 2018, more than 9% of the world's proven coal reserves were in India. More than 70% of the nation's coal reserves are found in the eastern states of Jharkhand, Orissa, Chhattisgarh, and West Bengal, which also happen to be home to the majority of the nation's hard coal seams. ",
        articleImg16: "",

        subtitle17: "",
        mediumText17: "",
        paragraph17:
            "The other important coal-producing Indian states are Madhya Pradesh, Andhra Pradesh, and Maharashtra. Most of the nation's lignite reserves are located in the state of Tamil Nadu in the south.",
        articleImg17: "",

        subtitle18: "",
        mediumText18: "",
        paragraph18:
            "India comes in second to China in terms of both production and consumption of coal. In 2018, it generated 771Mt of coal, which is 7.9% of the world's total. 12% of the coal consumed globally is consumed in India. Coal is used to generate more than 70% of the electricity in India.",
        articleImg18: "",

        subtitle19: "6. Indonesia",
        mediumText19: "",
        paragraph19:
            "As of December 2018, Indonesia's proven coal reserves made up 3.5% of the world's total proved coal reserves. ",
        articleImg19: "",

        subtitle20: "",
        mediumText20: "",
        paragraph20:
            "South Sumatra, East Kalimantan, and South Kalimantan are where the majority of the nation's coal reserves are located. More than half of Indonesia's total coal production is produced in the East Kalimantan province. The largest coal mine in Indonesia is Kaltim Prima, which is situated in East Kalimantan.",
        articleImg20: "",

        subtitle21: "",
        mediumText21: "",
        paragraph21:
            "The world's fourth-largest producer of coal is Indonesia. It became the world's largest coal exporter in 2018 after producing 549Mt of coal and exporting 439Mt of it. Indonesia is a significant regional coal exporter to Asian markets like China and India.",
        articleImg21: "",

        subtitle22: "7. Germany",
        mediumText22: "",
        paragraph22:
            "3.4% of the world's proven coal reserves are located in Germany, the country with the largest coal reserves in Europe. ",
        articleImg22: "",

        subtitle23: "",
        mediumText23: "",
        paragraph23:
            "More than 75% of the hard coal produced in Germany comes from the Saar Basin in the southwest and the Ruhr Coal Basin in the state of North Rhine-Westphalia. The bulk of the nation's lignite reserves are located in the Rhineland. Considered to be the largest brown coal mines in Europe, the North Rhine-Westphalia state's Garzweiler and Hambach open-cast coal mines.",
        articleImg23: "",

        subtitle24: "",
        mediumText24: "",
        paragraph24:
            "In 2018, 169Mt of coal was produced in Germany, of which 185Mt was brown coal. In 2018, it was responsible for 2.1% of the global coal consumption and 1.2% of the global coal output. Germany produces 43% of its electricity from coal.",
        articleImg24: "",

        subtitle25: "Conclusion ",
        mediumText25: "",
        paragraph25:
            "Many countries make use of coal as a source of energy, but not many countries produce it. The production of coal without a doubt greatly helps a country’s economy, especially if it is properly managed. This article presents a list of countries that have great coal reserves. ",
        articleImg25: "",
    },
    {
        id: 10,
        preview: "images/article-preview-10.webp",
        tag: "top companies",
        name: "Top ten Metallurgical enterprises on the internet.",
        paragraph1:
            "From towering skyscrapers to sleek smartphones, metals are the backbone of modern civilization. And behind every metal masterpiece, there's a metallurgical enterprise hard at work. These companies are the wizards of metal, extracting, processing, and manufacturing the materials that build our world. ",

        subtitle2: "",
        mediumText2: "",
        paragraph2:
            "In this article, we'll explore the top 10 metallurgical enterprises on the planet, and examine their company profile, history, products, and services. ",
        articleImg2: "",

        subtitle3: "Criteria for Selection: Factors considered for the ranking",
        mediumText3: "",
        paragraph3:
            "When it comes to determining the top metallurgical enterprises in the world, a number of factors must be considered. These Factors include ",
        articleImg3: "",

        list3: {
            listItem1: "● Production capacity",
            listItem2: "● Revenue",
            listItem3: "● Technological innovation",
            listItem4: "● Product quality",
            listItem5: "● Sustainability practices",
        },

        subtitle4: "",
        mediumText4: "",
        paragraph4:
            "All these play a role in ranking these companies. So, without further ado, let's take a closer look at the top 10 metallurgical enterprises of the planet!",
        articleImg4: "",

        tableLarge4: {
            tableHeader1: "Rank",
            tableHeader2: "Company",
            tableHeader3: "Production Capacity in million tonnes",
            tableItem1: "1.",
            tableItem2: "2.",
            tableItem3: "3.",
            tableItem4: "4.",
            tableItem5: "5.",
            tableItem6: "6.",
            tableItem7: "7",
            tableItem8: "8",
            tableItem9: "9",
            tableItem10: "10",
            tableItem11: "China Baowu Group",
            tableItem12: "ArcelorMittal ",
            tableItem13: "Nippon Steel Corporation",
            tableItem14: "POSCO",
            tableItem15: "JFE holdings",
            tableItem16: "Tata steel",
            tableItem17: "China Steel Corporation (CSC)",
            tableItem18: "Ansteel Group",
            tableItem19: "HBIS Group",
            tableItem20: "Rio Tinto",
            tableItem21: "119.95",
            tableItem22: "79",
            tableItem23: "40",
            tableItem24: "26.2",
            tableItem25: "33",
            tableItem26: "34",
            tableItem27: "16",
            tableItem28: "39",
            tableItem29: "46",
            tableItem30: "25",
        },

        subtitle5: "1. China Baowu Group ",
        mediumText5: "",
        paragraph5:
            "China Baowu Group, headquartered in Shanghai, is the world's largest steel producer by output. The company was formed in 2016 through the merger of two Chinese steelmakers, Baosteel Group and Wuhan Iron and Steel Corporation. ",
        articleImg5: "",

        subtitle6: "",
        mediumText6: "",
        paragraph6:
            "With a workforce of over 200,000 employees, China Baowu Group produces a wide range of steel products, including hot-rolled, cold-rolled, and coated steel, as well as steel pipes and special steel products. The company also has a significant presence in mining, producing iron ore, coking coal, and other minerals.",
        articleImg6: "",

        subtitle7: "2. ArcelorMittal",
        mediumText7: "",
        paragraph7:
            "ArcelorMittal is a multinational steel manufacturing company headquartered in Luxembourg. It is the largest steel producer in the world, with operations in over 60 countries and a workforce of over 150,000 employees. The company produces a wide range of products, including flat carbon steel, long carbon steel, stainless steel, and steel pipes and tubes. ",
        articleImg7: "",

        subtitle8: "",
        mediumText8: "",
        paragraph8:
            "ArcelorMittal also has a significant presence in the mining industry, producing iron ore and coal. ArcelorMittal has a rich history dating back to 1976, and its products have been used in a wide range of industries, including construction, automotive, and energy.",
        articleImg8: "",

        subtitle9: "3. Nippon Steel Corporation ",
        mediumText9: "",
        paragraph9:
            "Nippon Steel Corporation is a Japanese company and one of the largest steel producers in the world. Founded in 1950, Nippon Steel has a long history in the steel industry and has developed a reputation for producing high-quality steel products for a wide range of industries. ",
        articleImg9: "",

        subtitle10: "",
        mediumText10: "",
        paragraph10:
            "With a focus on innovation and sustainability, Nippon Steel is constantly pushing the boundaries of what's possible in steel production. In recent years, the company has developed advanced high-strength steels for the automotive industry, helping to make cars safer and more fuel-efficient.",
        articleImg10: "",

        subtitle11: "4. POSCO",
        mediumText11: "",
        paragraph11:
            "POSCO is a leading South Korean steelmaker that was founded in 1968. The company has a diverse range of products and services that cater to various industries such as automotive, construction, shipbuilding, and energy. ",
        articleImg11: "",

        subtitle12: "",
        mediumText12: "",
        paragraph12:
            "POSCO has also dedicated itself to innovation by creating cutting-edge steel technologies that have enhanced the sturdiness, longevity, and adaptability of their products. Additionally, the organization is committed to sustainability by launching programs to reduce CO2 emissions and foster eco-friendly production methods.",
        articleImg12: "",

        subtitle13: "5. JFE Holdings",
        mediumText13: "",
        paragraph13:
            "JFE Holdings is a Japanese conglomerate that operates in various fields such as steelmaking, engineering, and chemicals. It is the result of a merger between two Japanese steel giants, Kawasaki Steel and NKK, and has become one of the largest steelmakers in Japan. ",
        articleImg13: "",

        subtitle14: "",
        mediumText14: "",
        paragraph14:
            "The company prides itself on its cutting-edge technology, which enables it to produce high-quality steel products that are widely used in the automotive and construction industries. JFE Holdings also has a strong focus on sustainability, with a goal to reduce CO2 emissions by 30% by 2030.",
        articleImg14: "",

        subtitle15: "6. Tata Steel",
        mediumText15: "",
        paragraph15:
            "Tata Steel is one of the world's leading steel companies, with a presence in over 50 countries and manufacturing operations in 26 countries. The company was established in 1907 and has a rich history that spans over a century. Tata Steel produces a wide range of steel products, including flat steel, long steel, and coated steel. ",
        articleImg15: "",

        subtitle16: "",
        mediumText16: "",
        paragraph16:
            "The company is also a major supplier of steel to the automotive sector and has a strong focus on sustainable production. In recent years, Tata Steel has made significant investments in technology and innovation to improve its operations and stay competitive in the global market.",
        articleImg16: "",

        subtitle17: "7. China Steel Corporation (CSC)",
        mediumText17: "",
        paragraph17:
            "China Steel Corporation (CSC) is the largest integrated steel maker in Taiwan. Founded in 1971, the company has a total annual production capacity of 10 million tons. CSC offers a wide range of products, including hot and cold rolled coils, plates, wire rods, and bars, as well as specialty steel products for the automotive, construction, and energy industries. ",
        articleImg17: "",

        subtitle18: "",
        mediumText18: "",
        paragraph18:
            "The company has a strong commitment to sustainability and has implemented various initiatives to reduce its environmental impact, such as the use of recycled materials and the promotion of energy efficiency.",
        articleImg18: "",

        subtitle19: "8. Ansteel Group",
        mediumText19: "",
        paragraph19:
            "Ansteel Group is a Chinese state-owned steel producer with a long and storied history that dates back to 1948. Today, it ranks among the top ten metallurgical enterprises in the world, with an annual production capacity of over 39 million tons of steel.",
        articleImg19: "",

        subtitle20: "",
        mediumText20: "",
        paragraph20:
            "Ansteel's products are widely used in the construction, automotive, and energy industries, among others. The company's commitment to innovation, sustainable development, and environmental responsibility has earned it a reputation as a leader in the global steel industry.",
        articleImg20: "",

        subtitle21: "9. HBIS Group",
        mediumText21: "",
        paragraph21:
            "HBIS Group, also known as Hebei Iron and Steel Group Co., Ltd., is a state-owned steel company based in China. It is one of the largest steel producers in the world, with a production capacity of over 46 million tonnes of steel per year. ",
        articleImg21: "",

        subtitle22: "",
        mediumText22: "",
        paragraph22:
            "The company produces a wide range of steel products, including hot-rolled coils, cold-rolled coils, wire rods, and seamless steel pipes. In addition to steel production, HBIS Group is also involved in industries such as mining, logistics, and finance. The company has a global presence, with operations in countries such as the United States, Australia, and Brazil.",
        articleImg22: "",

        subtitle23: "10. Rio Tinto Group",
        mediumText23: "",
        paragraph23:
            "Rio Tinto Group, a British-Australian multinational metals and mining corporation, is one of the largest mining companies in the world. Founded in 1873, the company has operations in over 34 countries and specializes in mining aluminum, copper, diamonds, iron ore, uranium, and other minerals.",
        articleImg23: "",

        subtitle24: "",
        mediumText24: "",
        paragraph24:
            "The company is also committed to preserving the environment and has implemented measures to protect the biodiversity of the areas where it operates.",
        articleImg24: "",

        subtitle25: "Bottomline",
        mediumText25: "",
        paragraph25:
            "As the world's demand for steel and other metal products continues to rise, the importance of the metallurgical industry cannot be understated. The top 10 metallurgical enterprises on this list are leading the way in innovation, sustainability, and profitability. With their global reach and extensive expertise, these companies are well positioned to shape the future of the industry.",
        articleImg25: "",
    },
    {
        id: 11,
        preview: "images/article-preview-11.webp",
        tag: "top countries",
        name: "6 Leading coal production and export countries in the world",
        paragraph1:
            "For many years, the coal industry has contributed significantly to the world’s industrialization and provision of energy. The industry used to be a primary source of power in different parts of the world. Ten years ago, the coal mining industry in the United States made over 50% of electricity. It was referred to as the great all-star of the energy mix. However, over the past few years, things have changed significantly as a result of the reduction in coal demand. Experts suggest this decline is due to the reduction in price of natural gas. ",

        subtitle2: "",
        mediumText2: "",
        paragraph2:
            "Coal’s status as the great all-star of energy was challenged by natural gas, solar, wind, and other sources. Companies were looking into other natural resources to provide energy and sustain industrialization. However, in 2017, the global coal industry started experiencing growth and development. The world’s biggest coal consumers, such as the United States, China, India, and other countries started implementing policies and supporting the global coal mining market with funds and labor force. These policies helped establish coal’s status as a primary source of energy for powering industrialization. ",
        articleImg2: "",

        subtitle3: "",
        mediumText3: "",
        paragraph3:
            "According to the International Energy Agency, the global coal mining sector is expected to experience immense growth over the next few years. Experts believe that this growth will be due to the increase in coal production and exportation companies in different parts of the world. ",
        articleImg3: "",

        subtitle4: "",
        mediumText4: "",
        paragraph4:
            "This article will talk about the 9 top coal production and expiration companies that are currently contributing to the development of the global coal industry. ",
        articleImg4: "",

        tableLarge4: {
            tableHeader1: "S/N",
            tableHeader2: "Company",
            tableHeader3: "Revenue (Billion)",
            tableItem1: "1.",
            tableItem2: "2.",
            tableItem3: "3.",
            tableItem11:
                "it may have negative impacts on nearby residential areas",
            tableItem12: "China Shenhua Energy",
            tableItem13: "Arch Coal",
            tableItem21: "43.6",
            tableItem22: "37.6",
            tableItem23: "37.2",
        },

        subtitle5: "1. Coal India",
        mediumText5: "",
        paragraph5: "",
        articleImg5: "/images/article-11-img-1.webp",

        subtitle6: "",
        mediumText6: "",
        paragraph6:
            "Coal India Limited (CIL) is a reputable state-owned coal mining company. It was incorporated with a moderate output of about 79 million tons. It is the biggest coal producer in the world. With over 82 mining areas with seven owned coal producing subsidiaries, this company produced a record of 500 million tons in 2016 in both coal production and off-take. CIL continues to make efforts towards facilitating safe energy production in the country. For example, through effective partnership, the company continues to pursue initiatives to get high metallurgical coal assets and high CV thermal, which will help provide energy security. ",
        articleImg6: "",

        subtitle7: "",
        mediumText7: "",
        paragraph7:
            "Several speculations in the recent market show that Coal India is to purchase coking coal assets in Australia to help strengthen ts foreign coal assets stock. The company created a list of countries to invest in. The United States, Columbia, Indonesia, and Canada made the list. However, CIL has decided to invest in only Australia and South Africa for now to furnish its foreign coal assets. ",
        articleImg7: "",

        subtitle8: "2. BHP Billiton Limited",
        mediumText8: "",
        paragraph8:
            "BHP Billiton started as a tin mine on Belitung Island in Indonesia in the 1860s. The company started as a merger between Billiton and BHP ( a lead, silver, and zinc producer in Australia). It is now one of the biggest diversified natural resources companies in the world. In 2015, the company was split into four key pillars; coal, petroleum, iron ore, and copper. It also features a small fifth pillar that focuses on producing potash. ",
        articleImg8: "",

        subtitle9: "",
        mediumText9: "",
        paragraph9:
            "BHP Billiton Limited described its agenda for coal mining business in 2016. The company outlined its primary objective as improving outcomes by lowering costs, boosting productivity, and releasing latent capacity. The company’s operation continues to stay cash positive. BHP believes there will be an increase in market versatility in the future. ",
        articleImg9: "",

        subtitle10: "3. Shenhua Group",
        mediumText10: "",
        paragraph10:
            "Shenhua group was created in 1995. It is the most effective and prominent state-owned company that operates under the State Council. It is one of the biggest organizations that feature the one-stop business of transportation, operation, and production. Coal business is the company’s foundation. It integrates other aspects of the coal business, such as railroad, aviation, port, electricity, coal-to-liquid, and coal-to-chemical. It is the biggest coal mining company in China, which features the biggest level of modernization and the biggest distribution in the world. ",
        articleImg10: "",

        subtitle11: "",
        mediumText11: "",
        paragraph11:
            "The China government recently approved the merger of Shenhua group corp with a major power company, China Guodian Corp. This merger has an asset of about $271 billion and is expected to be the second biggest company by revenue and installed capacity in the world. ",
        articleImg11: "",

        subtitle12: "4. Arch Coal",
        mediumText12: "REVENUE OF ARCH COAL FROM 2010-2022",
        paragraph12: "",
        articleImg12: "",

        tableLarge12: {
            tableHeader1: "S/N",
            tableHeader2: "Year",
            tableHeader3: "Revenue in million dollars",
            tableItem1: "1.",
            tableItem2: "2.",
            tableItem3: "3.",
            tableItem4: "4.",
            tableItem5: "5.",
            tableItem6: "6.",
            tableItem7: "7.",
            tableItem8: "8.",
            tableItem9: "9.",
            tableItem10: "10.",
            tableItem11: "11.",
            tableItem12: "12.",
            tableItem13: "13.",
            tableItem14: "2022",
            tableItem15: "2021",
            tableItem16: "2020",
            tableItem17: "2019",
            tableItem18: "2018",
            tableItem19: "2017",
            tableItem20: "2016",
            tableItem21: "2015",
            tableItem22: "2014",
            tableItem23: "2013",
            tableItem24: "2012",
            tableItem25: "2011",
            tableItem26: "2010",
            tableItem27: "3,724",
            tableItem28: "2,208.04",
            tableItem29: "1,467.59",
            tableItem30: "2,294.35",
            tableItem31: "2,451.79",
            tableItem32: "2,324.6",
            tableItem33: "1,977.4",
            tableItem34: "2,573.3",
            tableItem35: "2,937.1",
            tableItem36: "3,014.4",
            tableItem37: "3,768.1",
            tableItem38: "3,883",
            tableItem39: "2,817.4",
        },

        subtitle13: "",
        mediumText13: "",
        paragraph13:
            "Arch Coal is a coal producing company that is based in Creve Coeur. It was greatly affected by the reduction in demand for coal. It applied for a bankruptcy protection scheme in 2019 to reduce its debts. The company created an effective restructuring strategy that helped revive its finances and reputation in the global coal mining industry. ",
        articleImg13: "",

        subtitle14: "",
        mediumText14: "",
        paragraph14:
            "With nearly 96 million tons, Arch Coal is now one of the most reputable coal-producing companies in the world. It is one of the most effective mining companies in the United States with high-quality reserves and low-cost mining developments. It accounts for 13% of the country’s coal supply. ",
        articleImg14: "",

        subtitle15: "5. Anglo American Plc",
        mediumText15: "",
        paragraph15:
            "Anglo American Plc is more than a century old with roots from South Africa’s diamond and gold fields. It is now one of the biggest diversified mining companies in the world. The company has an extensive coal portfolio that is geographically diverse, with thermal coal assets for producing electricity in South Africa and Columbia. It also has a metallurgical coal asset in Australia. The company is the third biggest supplier of metallurgical coal. ",
        articleImg15: "",

        subtitle16: "",
        mediumText16: "",
        paragraph16:
            "Anglo American PLC sold the Eskimo-linked thermal coal operations in South Africa for 167 million dollars in 2017. This enhanced the company’s strategic overhaul and helped it focus on the other three resources.",
        articleImg16: "",

        subtitle17: "6. Jiangxi Copper Co. Ltd. ",
        mediumText17: "",
        paragraph17:
            "Jiangxi Copper Co. Ltd, a China-based company has a revenue of $40.1 billion, a market cap of $7.7 billion, and a net income of $271.4 million. The company has a trailing total return of 2.9% every year. It is a coal producing company that integrates the extraction and processing of valuable and scattered metal into its mining process. The company produces copper toil, copper tube, copper rod, sulphuric acid, silver, gold, and copper cathode. ",
        articleImg17: "",

        subtitle18: "Conclusion",
        mediumText18: "",
        paragraph18:
            "Coal is one of the most important sources of energy for powering industrialization in different parts of the world. Many coal-producing companies play important roles to ensure it gets to all the consuming countries in the world. The companies listed in this article meet all the criteria to remain coal- producing and exporting giants in the global industry. ",
        articleImg18: "",
    },
    {
        id: 12,
        preview: "images/article-preview-12.webp",
        tag: "analysis",
        name: "Australian coal market, analysis, trends",
        articleImg1: "",
        paragraph1:
            "This article discusses the Australia coal market study period of 2019 to 2028. Australia is a major producer of coal and a significant net exporter in the globe. Based on analysis, the country’s market is expected to grow within the period of 2020–2025, the Australian coal market is anticipated to expand at a CAGR of more than 1.4%.",

        subtitle2: "",
        mediumText2: "",
        paragraph2:
            "However because they take into account losses that happen during mining and processing, recoverable resources offer a more precise evaluation of coal endowment. Australia had a total of 163,330 Mt of recoverable identified black coal resources as of December 2020, of which 74,147 Mt were recoverable EDR. In terms of economically proven black coal deposits, Australia comes in fourth place overall, behind China, India, and the United States. ",
        articleImg2: "",

        subtitle3: "",
        mediumText3: "",
        paragraph3:
            "The expected recoverable Identified Resource for brown coal at the end of 2020 was 436,652 Mt, of which 74,039 Mt were recoverable. After Russia, Germany, and the United States, Australia comes second internationally for economically proven brown coal deposits.",
        articleImg3: "",

        subtitle4: "",
        mediumText4: "",
        paragraph4:
            "The Australian coal market is expanding as a result of factors like rising energy demand and the availability of significant coal deposits. The environmental effects of coal mining as well as the switch to other energy sources like renewables for power generation and natural gas are projected to limit the growth of the coal market in Australia during the course of the forecast period.",
        articleImg4: "",

        subtitle5: "Australia Coal Market Analysis ",
        mediumText5: "",
        paragraph5:
            "The industry under study is anticipated to be driven by the rising demand for bituminous coal deposits, which account for a significant portion of the Australian coal market. ",
        articleImg5: "",

        subtitle6: "",
        mediumText6: "",
        paragraph6:
            "The use of new technologies in coal mining operations, such as  3D mapping, geospatial data, and hyperspectral core imaging, is anticipated to open up opportunities for the Australian coal market in years to come. ",
        articleImg6: "",

        subtitle7: "",
        mediumText7: "",
        paragraph7:
            "During the course of the projected period, rising demand from the power generating sector is anticipated to drive the Australian coal market.",
        articleImg7: "",

        subtitle8: "Australia's exports and the world's supply of coal ",
        mediumText8: "",
        paragraph8:
            "Global coal production has greatly expanded over the past few decades, reflecting the significant structural growth in worldwide coal demand. Since 2010, the average annual global production has been close to 8,000 Mt, roughly double that of the early 1990s. The majority of the increase in output over the past few decades has been fueled by China, which is by far the greatest producer of coal, accounting for about half of annual global production. ",
        articleImg8: "",

        subtitle9: "",
        mediumText9: "",
        paragraph9:
            "Europe and the United States are two more significant producers. However, since steel output has decreased and alternative sources of energy generation have replaced coal-powered generation, production in these economies has decreased since the 2000s.",
        articleImg9: "",

        subtitle10: "Australia Coal Market Trends",
        mediumText10: "",
        paragraph10:
            "Experts and researchers have been able to study the country’s coal market enough to understand and in fact predict the market trends to a certain extent. The trend shapes the market, so a better understanding of it can help you stay in control. Here are some major happenings in the market: ",
        articleImg10: "",

        subtitle11: "",
        mediumText11: "",
        paragraph11:
            "Australia produced 503 million tonnes of coal in 2019, moving it up to sixth place globally. In 2019, the nation exported 393 million tonnes of coal, ranking second only to Indonesia in terms of exports of coal at 27.4%. ",
        articleImg11: "",

        subtitle12: "",
        mediumText12: "",
        paragraph12:
            "Black coal is the phrase used in Australia for bituminous coal. Coke, which is mostly used in the production of steel and iron, and power generation are two major uses for bituminous coals. Bituminous coal is employed in a variety of industrial settings, including the production of paper, cement, alumina refineries, and other metallurgical processes.",
        articleImg12: "",

        subtitle13: "",
        mediumText13: "",
        paragraph13:
            "The states of New South Wales, Tasmania, Queensland, South Australia, and Western Australia all contain black coal resources, however New South Wales and Queensland account for the majority of all known resources in Australia. In addition, the biggest coal-producing states are these two.",
        articleImg13: "",

        subtitle14: "",
        mediumText14: "",
        paragraph14:
            "Australia generated 253,420 thousand short tons of bituminous coal in 2018. The country experienced a tremendous increase of about 150,000 thousand short tons in coal production between 1999 and 2018, expanding at an average annual rate of 4.48%. ",
        articleImg14: "",

        subtitle15: "",
        mediumText15: "",
        paragraph15:
            "As a result of its large bituminous coal reserves, greatest production, and highest exports, Australia is projected to have a strong demand for bituminous coal during the forecast period.",
        articleImg15: "",

        subtitle16:
            "How the power generation industry affects the coal market ",
        mediumText16: "",
        paragraph16: "",
        articleImg16: "/images/article-12-img-1.webp",

        subtitle17: "",
        mediumText17: "",
        paragraph17:
            "Australia’s coal market is anticipated to be driven by growth in electricity generation. This is mostly a result of the country's rising coal consumption and rising energy demand. ",
        articleImg17: "",

        subtitle18: "",
        mediumText18: "",
        paragraph18:
            "The generation of electricity increased by 1.3% between 2014 and 2019. As coal is the main energy source in the nation, the increased need for generation of power is thus anticipated to improve the coal market there.",
        articleImg18: "",

        subtitle19: "",
        mediumText19: "",
        paragraph19:
            "The majority of the energy used to produce electricity in the nation is still coal, which generated roughly 56% of the electricity used nationwide in 2019. Australia produced 149.5 TWh of energy from coal in 2019. ",
        articleImg19: "",

        subtitle20: "",
        mediumText20: "",
        paragraph20:
            "Australia's coal reserves total 149079 million tonnes. The majority of the Australian coal market is made up of these, with black coal being mostly used for coal power generation. As a result, the market is anticipated to be driven by coal power generation in years to come. ",
        articleImg20: "",

        subtitle21:
            "Australia coal companies that significantly influence the market ",
        mediumText21: "",
        paragraph21:
            "Some big companies in the country decide how the market trend is going to be. These companies tend to work closely with the government in order to control the market’s fluctuations. Some of these companies include: ",
        articleImg21: "",

        list21: {
            listItem1: "● Bloomfield Collieries Pty Ltd, BHP Group Ltd",
            listItem2: "● Centennial Coal Company Ltd",
            listItem3: "● HardRock Coal Mining Pty Ltd",
            listItem4: "● Yancoal Australia Ltd.",
        },

        subtitle22: "Conclusion ",
        mediumText22: "",
        paragraph22:
            "Australia has major dealings with coal as it is a major resource for the country. The country operates on coal for electricity and other power generation. The cola market trend is always under observation to ensure that there are no sudden movements. Luckily the country has been experiencing growth in the industry, which explains good management and control. ",
        articleImg22: "",
    },
    {
        id: 13,
        preview: "images/article-preview-13.webp",
        tag: "analysis",
        name: "Chinese coal market analysis, and trends",
        paragraph1:
            "China is the world's largest producer and user of coal. The enormous demand for coal in China motivates foreign coal producers to increase production levels. Also, the majority of the coal mines in other countries are opencast, which facilitates an increase in production.",

        subtitle2: "",
        mediumText2: "",
        paragraph2:
            "Between 2022 and 2027, the Chinese coal market is anticipated to grow at a CAGR of over 5%. The COVID-19 outbreak in the first quarter of 2020 had a mild influence on the Chinese coal industry, as demand increased by 0.3% from 2019 to 2020. In addition, the country-wide lockdowns that have been induced have reduced coal consumption. ",
        articleImg2: "",

        subtitle3: "",
        mediumText3: "",
        paragraph3:
            "Throughout the projected period, the market is anticipated to be driven by elements such as rising electricity demand and power generation as well as rising investments in coal-based power plants. The growth of the Chinese coal market is anticipated to be constrained over the projected period by increased environmental concerns regarding greenhouse gas emissions and global warming.",
        articleImg3: "",

        subtitle4: "Analysis of the China coal market ",
        mediumText4: "",
        paragraph4:
            "China's significant plan to build more coal-fired power plants during the projection period is expected to support the power generating segment's dominance of the market. ",
        articleImg4: "",

        subtitle5: "",
        mediumText5: "",
        paragraph5:
            "Coal power plants in China are anticipated to consume a sizable amount of coal due to the country's rising need for electricity. By 2040, there will be a demand for about 4,400 megatons of coal equivalent in the Asia-Pacific area, with the power industry accounting for the majority of this demand. In the following years, market participants are anticipated to benefit from this demand for coal.",
        articleImg5: "",

        subtitle6: "",
        mediumText6: "",
        paragraph6:
            "The expansion of China’s coal market over the projected period is anticipated to be constrained by growing environmental concerns regarding greenhouse gas emissions and global warming.",
        articleImg6: "",

        subtitle7: "China’s coal market trends ",
        mediumText7: "",
        paragraph7: "",
        articleImg7: "/images/article-13-img-1.webp",

        subtitle8: "",
        mediumText8: "",
        paragraph8:
            "More than 49% of the world's coal consumption in 2020 was accounted for by China, which also used coal for about 57% of its main energy needs. ",
        articleImg8: "",

        subtitle9: "",
        mediumText9: "",
        paragraph9:
            "The swift economic rebound following the epidemic was a major factor in China's production of 7.42 trillion kWh of electricity in 2020, an increase of 2.7% from 2019. China was one of the few nations to experience an increase in its energy consumption in 2020, and it saw the largest increase internationally.",
        articleImg9: "",

        subtitle10: "",
        mediumText10: "",
        paragraph10:
            "Moreover, China installed 38.4 GW of new coal plants in 2020, which is more than three times the 11.9 GW installed globally. Because of China's significant global increase in coal consumption, the market is anticipated to grow throughout the forecast period.",
        articleImg10: "",

        subtitle11: "",
        mediumText11: "",
        paragraph11:
            "About three times as many coal power projects as were allowed in 2019 received construction permission from Chinese provinces in 2020, totaling 36.9 GW. Furthermore, China is developing 247 GW of coal power (88.1 GW under construction and 158.7 GW proposed). When compared to 2019, there has been an increase of 21%. (i.e., 205 GW).",
        articleImg11: "",

        subtitle12: "",
        mediumText12: "",
        paragraph12:
            "The supply of thermal coal keeps growing, and power plant coal inventories are quickly recovering, according to the National Development and Reform Commission (NDRC). Since November 2021, power plants have been using an average of 7.74 million tons of coal per day, and coal inventories have grown to 1.6 million tons. ",
        articleImg12: "",

        subtitle13: "",
        mediumText13: "",
        paragraph13:
            "Based on the aforementioned considerations, power generation is therefore projected to predominate the Chinese coal market during the forecast period.  ",
        articleImg13: "",

        subtitle14: "Factors that may influence China’s coal market ",
        mediumText14: "",
        paragraph14:
            "Environmental protection laws and other reasons limit the amount of coal that China may produce. Due to unsatisfactory environmental protection facilities, some small and medium-sized coal mines in recent years have had to reduce or even discontinue their operations.",
        articleImg14: "",

        subtitle15: "",
        mediumText15: "",
        paragraph15:
            "Due to China's economy's exceptional expansion and other reasons including its quick industrialization and urbanization, there is a strong need for power. As a result, coal has become increasingly necessary to provide electricity in recent years. ",
        articleImg15: "",

        subtitle16: "",
        mediumText16: "",
        paragraph16:
            "The development rate has slowed due to China's massive steel production and the growing environmental worries about pollution and greenhouse gas emissions from coal-based power plants. From 68% in 2012 to about 57% in 2020, the country reduced the proportion of coal in its total energy consumption. For the course of the prediction, this decline is anticipated to persist.",
        articleImg16: "",

        subtitle17: "",
        mediumText17: "",
        paragraph17:
            "The country's energy sector is still pushing for a capacity goal in the upcoming five-year plan that would allow for the construction of up to 200 additional coal-fired generating units by 2025. ",
        articleImg17: "",

        subtitle18: "",
        mediumText18: "",
        paragraph18:
            "As the country’s energy mix continues to become more environmentally friendly, a decrease in the percentage of coal charge is expected.",
        articleImg18: "",

        subtitle19: "",
        mediumText19: "",
        paragraph19:
            "The consumption of coal has decreased from about 68% over the past ten years to 57% in 2020 thanks in part to the quick development of renewable energy generation and the rising use of natural gas. In 2020, the amount of greenhouse gases will increase by 0.6%.",
        articleImg19: "",

        subtitle20: "",
        mediumText20: "",
        paragraph20:
            "Market growth is anticipated to be hampered by the environmental policies that have reduced the growth rate of coal consumption and the rising share of renewable energy sources.",
        articleImg20: "",

        subtitle21: "China Coal Industry ",
        mediumText21: "",
        paragraph21:
            "There are some top coal companies in China that plays a big part in the market fluctuations, they include: ",
        articleImg21: "",

        list21: {
            listItem1: "● China Coal Energy Group Co. Ltd ",
            listItem2: "● China Shenhua Energy Company Limited",
            listItem3: "● China Power International Development Limited",
            listItem4: "● Yanzhou Coal Mining Company Limited",
            listItem5: "● Huadian Power International Corporation Limited.",
        },

        subtitle22: "Top news about china’s coal market ",
        mediumText22: "",
        paragraph22:
            "China's daily coal output increased to 11.93 million tons in November 2021, according to a report from the National Development and Reform Commission (NDRC). ",
        articleImg22: "",

        subtitle23: "",
        mediumText23: "",
        paragraph23:
            "The Shanghaimiao facility, with a 1,000 MW capacity for power generation, was finished in China in December 2021. The undertaking is the biggest coal-fired power plant in the nation. ",
        articleImg23: "",

        subtitle24: "",
        mediumText24: "",
        paragraph24:
            "The construction of a new USD 1.10 billion coal-fired power station with 2 GW of generating capacity was permitted by Zhejiang, a coastal province in eastern China, in January 2022.",
        articleImg24: "",

        subtitle25: "Conclusion ",
        mediumText25: "",
        paragraph25:
            "China is regarded as one of the top countries to deal with coal in large amounts. Coal plays a huge part in the country’s economy, it also helps in more that 50% of the country’s economy. Many factors affect the market trend, but the country try’s everything possible to keep it stable.",
        articleImg25: "",

        subtitle26: "",
        mediumText26: "",
        paragraph26: "",
        articleImg26: "",

        subtitle27: "",
        mediumText27: "",
        paragraph27: "",
        articleImg27: "",
    },
    {
        id: 14,
        preview: "images/article-preview-14.webp",
        tag: "world coal",
        name: "Coal mining in African countries",
        paragraph1:
            "Over the past few years, there has been an increasing need for energy, and as a result, African nations with abundant coal reserves have been intensifying their mining activities. But with this boom comes a range of challenges, from environmental concerns to the impact on local communities.",

        subtitle2: "",
        mediumText2: "",
        paragraph2:
            "In this article, we'll take a deep dive into the world of African coal mining and everything you need to know about it ",
        articleImg2: "",

        subtitle3: "Major Players in African Coal Mining Industry",
        mediumText3: "",
        paragraph3:
            "The African continent is home to some of the world's largest coal reserves. Some of the major countries where these reserves can be found include ",
        articleImg3: "",

        table3: {
            tableHeader1: "Country",
            tableHeader2: "Production volume in thousand metric tones",
            tableItem1: "South Africa",
            tableItem2: "Mozambique ",
            tableItem3: "Botswana ",
            tableItem4: "Zimbabwe ",
            tableItem5: "Zambia ",
            tableItem6: "Tanzania ",
            tableItem7: "Niger ",
            tableItem8: "Eswatini",
            tableItem9: "Malawi ",
            tableItem10: "Nigeria ",
            tableItem11: "254,411",
            tableItem12: "13,890",
            tableItem13: "1,970",
            tableItem14: "1,932",
            tableItem15: "1,321",
            tableItem16: "710",
            tableItem17: "245",
            tableItem18: "110",
            tableItem19: "47",
            tableItem20: "45",
        },

        subtitle4: "",
        mediumText4: "1. South Africa",
        paragraph4: "",
        articleImg4: "/images/article-14-img-1.webp",

        subtitle5: "",
        mediumText5: "",
        paragraph5:
            "South Africa ranks first on the list of top coal-producing countries in Africa, with its coal mines producing more than 250 million tons annually. The country's coal industry is one of the largest employers in the mining sector and has been a crucial part of South Africa's economy for many years.",
        articleImg5: "",

        subtitle6: "2. Mozambique",
        mediumText6: "",
        paragraph6: "",
        articleImg6: "",

        subtitle7: "",
        mediumText7: "",
        paragraph7:
            "Mozambique is rapidly emerging as a significant coal-producing country in Africa. The country's coal reserves have attracted significant investments from international mining companies, contributing to the country's economic growth.",
        articleImg7: "",

        subtitle8: "3. Botswana",
        mediumText8: "",
        paragraph8: "",
        articleImg8: "",

        subtitle9: "",
        mediumText9: "",
        paragraph9:
            "Botswana is also a major player in the African coal industry, with its reserves estimated to be around 212 billion tons. The country's coal mines have created jobs for thousands of people, and the industry has become a vital source of income for the government.",
        articleImg9: "",

        subtitle10: "4. Zimbabwe ",
        mediumText10: "",
        paragraph10: "",
        articleImg10: "",

        subtitle11: "",
        mediumText11: "",
        paragraph11:
            "Zimbabwe is known for its rich mineral resources, and coal is no exception. The country has significant coal reserves, and its coal industry has been growing steadily in recent years.",
        articleImg11: "",

        subtitle12: "",
        mediumText12: "",
        paragraph12:
            "Other countries such as Zambia, Tanzania, Niger, Eswatini, Malawi, and Nigeria also have significant coal reserves and are working towards increasing their production levels.",
        articleImg12: "",

        subtitle13: "Environmental Impact of Coal Mining in Africa",
        mediumText13: "",
        paragraph13:
            "Coal mining has a significant impact on the environment, and African countries are not immune to this.",
        articleImg13: "",

        subtitle14: "",
        mediumText14: "● Air pollution",
        paragraph14:
            "The burning of coal releases harmful pollutants like sulfur dioxide, nitrogen oxides, and particulate matter into the air, which can cause respiratory problems, cardiovascular diseases, and other health problems.",
        articleImg14: "",

        subtitle15: "",
        mediumText15: "● Water pollution",
        paragraph15:
            "Mining activities can contaminate water sources with heavy metals and chemicals like arsenic, cadmium, and mercury, which can be harmful to aquatic life and human health. In addition, coal mining can disrupt natural habitats and biodiversity, leading to the extinction of certain species.",
        articleImg15: "",

        subtitle16: "",
        mediumText16: "● Deforestation and soil erosion ",
        paragraph16:
            "Coal mining often involves the removal of large amounts of land and vegetation, leading to deforestation and soil erosion. This can have long-lasting effects on the environment, such as loss of biodiversity, degradation of soil quality, and altered water cycles.",
        articleImg16: "",

        subtitle17: "",
        mediumText17: "● Greenhouse gas emissions ",
        paragraph17:
            "These emissions exacerbate climate change and its effects on the environment.",
        articleImg17: "",

        subtitle18: "Socio-economic Implications of Coal Mining in Africa",
        mediumText18: "",
        paragraph18:
            "The socio-economic implications of coal mining in Africa are diverse and far-reaching. Here are some of the most significant implications of this industry:",
        articleImg18: "",

        subtitle19: "",
        mediumText19: "",
        paragraph19:
            "● Job Creation: Coal mining in Africa has the potential to create jobs and contribute to economic growth. The industry requires a diverse workforce, ranging from highly skilled engineers to manual laborers, providing employment opportunities for people across various educational backgrounds and skill levels.",
        articleImg19: "",

        subtitle20: "",
        mediumText20: "",
        paragraph20:
            "● Health and Safety Concerns: Coal mining is a dangerous occupation, and miners are exposed to hazards such as dust, noise, and chemical exposure. The health and safety of coal miners must be a top priority, and measures such as proper ventilation, protective gear, and training programs need to be implemented.",
        articleImg20: "",

        subtitle21: "",
        mediumText21: "",
        paragraph21:
            "● Environmental Degradation: Coal mining is known for its negative impact on the environment, such as land degradation, water pollution, and air pollution. The industry must take steps to minimize its impact on the environment through the adoption of sustainable practices and the implementation of stricter regulations.",
        articleImg21: "",

        subtitle22: "",
        mediumText22: "",
        paragraph22:
            "● Energy Security: Coal remains an important energy source in many African countries, providing a reliable and affordable source of electricity. The continued development of the coal mining industry in Africa can contribute to the continent's energy security and reduce dependence on imported energy sources.",
        articleImg22: "",

        subtitle23: "Technological Advancements in African Coal Mining",
        mediumText23: "",
        paragraph23:
            "Technological advancements have significantly improved coal mining operations in African countries. Some of the most notable technological advancements include ",
        articleImg23: "",

        subtitle24: "",
        mediumText24: "",
        paragraph24:
            "● Longwall Mining: This is a mining technique that uses automated machinery to extract coal from large rectangular blocks of coal called 'longwalls.' Longwall mining is faster and more efficient than traditional mining methods and is being adopted in African coal mines.",
        articleImg24: "",

        subtitle25: "",
        mediumText25: "",
        paragraph25:
            "● Remote Sensing Technology: This technology uses satellite images and airborne sensors to gather data on coal deposits and mining operations. Remote sensing technology has made it easier to locate and assess coal reserves, monitor mine operations, and manage environmental impacts.",
        articleImg25: "",

        subtitle26: "",
        mediumText26: "",
        paragraph26:
            "● Automated Haulage Systems: These are computer-controlled vehicles that transport coal from the mine to the processing plant or port. Automated haulage systems have increased the efficiency of coal transportation, reduced costs, and improved safety.",
        articleImg26: "",

        subtitle27: "",
        mediumText27: "",
        paragraph27:
            "● Advanced Safety Equipment: New safety equipment such as proximity sensors, warning systems, and gas detectors have significantly reduced the risk of accidents and improved worker safety in African coal mines.",
        articleImg27: "",

        subtitle28: "Coal Mining Regulations and Policies in African Countries",
        mediumText28: "",
        paragraph28:
            "Coal mining in African countries is subject to various regulations and policies that aim to ensure the industry operates in a safe, responsible, and sustainable manner. Some of the key regulations and policies that impact coal mining in Africa include ",
        articleImg28: "",

        subtitle29: "",
        mediumText29: "",
        paragraph29:
            "● Environmental regulations. Environmental regulations help to minimize the environmental impact of coal mining activities. They may include requirements for the reclamation of land, the management of waste, and the reduction of greenhouse gas emissions.",
        articleImg29: "",

        subtitle30: "",
        mediumText30: "",
        paragraph30:
            "● Labor regulations. These regulations  are intended to protect the rights of workers in the coal mining industry. They may include regulations related to working conditions, wages, and benefits, as well as the prevention of child labor and forced labor.",
        articleImg30: "",

        subtitle31: "",
        mediumText31: "",
        paragraph31:
            "● Health and safety regulations. These help to ensure the safety and wellbeing of workers in the coal mining industry. They may include regulations related to equipment and machinery, the use of protective gear, and the prevention of accidents and injuries.",
        articleImg31: "",

        subtitle32: "",
        mediumText32: "",
        paragraph32:
            "There are also specific mining codes and tax laws in each country which regulates mining activities. These regulations govern the ownership, management, and financing of mining projects. ",
        articleImg32: "",

        subtitle33: "Future of Coal Mining in Africa",
        mediumText33: "",
        paragraph33:
            "While some argue that the demand for coal will continue to increase in the coming years, others predict a decline due to environmental concerns and the shift towards renewable energy sources. Now, African countries with large coal reserves face a dilemma of balancing economic growth and environmental sustainability. However, advancements in technology are making coal mining safer and more efficient.",
        articleImg33: "",
    },
    {
        id: 15,
        preview: "images/article-preview-15.webp",
        tag: "world coal",
        name: "Coal industry of Kazakhstan",
        articleImg1: "",
        paragraph1:
            "Kazakhstan is the world's tenth-largest coal producer, with a coal reserve base of over 33 billion tons. The coal industry has played a significant role in the country's economy, accounting for approximately 4% of Kazakhstan's GDP and employing over 40,000 people. The industry has been a major contributor to the country's energy sector, with coal-fired power plants generating over 50% of Kazakhstan's electricity.",

        subtitle2: "",
        mediumText2: "",
        paragraph2:
            "In this article, we will delve into the history and every other thing you need to know about Kazakhstan's coal industry",
        articleImg2: "",

        subtitle3: "Overview of Kazakhstan's Coal Reserves",
        mediumText3: "",
        paragraph3:
            "Kazakhstan is a country that is rich in natural resources, and one of the most important of these resources is coal. The country has significant coal reserves, which have played a major role in its economic growth over the years. In fact, Kazakhstan is one of the top coal-producing countries in the world and has some of the largest coal reserves on the planet.",
        articleImg3: "",

        subtitle4: "",
        mediumText4: "",
        paragraph4:
            "The coal reserves in Kazakhstan are estimated to be around 33.6 billion tons, which is the second-largest in the world after the United States. The majority of these reserves are located in the central and northern regions of the country, particularly in the Karaganda and Ekibastuz coal basins. The Karaganda coal basin alone is estimated to contain around 45% of the country's total coal reserves.",
        articleImg4: "",

        subtitle5: "",
        mediumText5: "",
        paragraph5:
            "Kazakhstan's coal reserves are a vital resource for the country's economy, providing a significant portion of the country's energy needs and supporting its industrial sector. The coal industry has been a major contributor to the country's GDP, generating significant revenue from exports to countries like China, Russia, and Ukraine.",
        articleImg5: "",

        subtitle6: "Coal Mining Operations and Production in Kazakhstan",
        mediumText6: "",
        paragraph6: "",
        articleImg6: "",

        table6: {
            tableHeader1: "Coal mine ",
            tableHeader2: "Estimated coal reserves in billion tonnes ",
            tableItem1: "Bogatyr Komir",
            tableItem2: "Karazhyra",
            tableItem3: "Vostochny",
            tableItem4: "Shubarkol",
            tableItem5: "Maikuben West",
            tableItem11: "4.7",
            tableItem12: "1.3",
            tableItem13: "3.2",
            tableItem14: "2.4",
            tableItem15: "0.39",
        },

        subtitle7: "",
        mediumText7: "",
        paragraph7:
            "The coal mining operations in Kazakhstan are spread across several regions, including Karaganda, Ekibastuz, Pavlodar, and the Turgay Basin.",
        articleImg7: "",

        subtitle8: "The country's five largest coal mines include ",
        mediumText8: "",
        paragraph8: "",
        articleImg8: "",

        subtitle9: "",
        mediumText9: "1. Bogatyr Komir",
        paragraph9:
            "This is the largest coal mine in Kazakhstan and one of the largest in the world. The mine is located in the Ekibastuz coal basin, in the Pavlodar region, and has estimated coal reserves of around 4.7 billion tonnes. The mine is operated by Bogatyr Komir, a joint venture between the Kazakh government and Samruk-Energy, a state-owned energy company.",
        articleImg9: "",

        subtitle10: "",
        mediumText10: "2. Karazhyra",
        paragraph10:
            "Karazhyra is located in the Karaganda region. The mine has estimated coal reserves of around 1.3 billion tonnes and is operated by ArcelorMittal Temirtau, a subsidiary of ArcelorMittal.",
        articleImg10: "",

        subtitle11: "",
        mediumText11: "3. Vostochny",
        paragraph11:
            "Vostochny is located in the Pavlodar region. The mine has estimated coal reserves of around 3.2 billion tonnes and is operated by Eurasian Energy Corporation (EEC), a subsidiary of ERG.",
        articleImg11: "",

        subtitle12: "",
        mediumText12: "4. Shubarkol",
        paragraph12:
            "Shubarkol is located in the Karaganda region. The mine has estimated coal reserves of around 2.4 billion tonnes and is operated by Shubarkol Komir, a subsidiary of ERG.",
        articleImg12: "",

        subtitle13: "",
        mediumText13: "5. Maikuben West",
        paragraph13:
            "Maikuben West is located in the Karaganda region. The mine has estimated coal reserves of around 390 million tonnes and is operated by Maikuben West LLP, a subsidiary of ERG.",
        articleImg13: "",

        subtitle14: "",
        mediumText14: "",
        paragraph14:
            "Kazakhstan's coal mining industry employs over 40,000 people, making it an important source of employment in the country. In recent years, Kazakhstan's coal mining industry has continued to grow, with production at 102 million tons in 2022. ",
        articleImg14: "",

        subtitle15: "Export of Coal from Kazakhstan",
        mediumText15: "",
        paragraph15:
            "Kazakhstan produces around 100 million tonnes per year. Of this, a significant proportion is exported to countries such as China, Russia, and Ukraine.",
        articleImg15: "",

        subtitle16: "",
        mediumText16: "",
        paragraph16:
            "One of the key reasons for the success of Kazakhstan's coal exports is the country's strategic location. The country is situated at the heart of the Eurasian landmass, with well-developed transportation infrastructure that allows for easy access to markets in Europe and Asia. The Caspian Sea, which borders Kazakhstan to the west, also provides a convenient outlet for coal shipments to countries in the Middle East.",
        articleImg16: "",

        subtitle17: "",
        mediumText17: "",
        paragraph17:
            "Kazakhstan's coal exports have also benefited from the country's competitive pricing. The cost of production in Kazakhstan is relatively low compared to other major coal-producing countries such as Australia and the United States. This has allowed Kazakhstani coal to be sold at competitive prices, which has helped to drive demand from buyers in countries such as China, which has a voracious appetite for coal.",
        articleImg17: "",

        subtitle18: "Investment in the Coal Industry in Kazakhstan",
        mediumText18: "",
        paragraph18:
            "With the growing demand for coal, the country is investing heavily in the development and expansion of its coal mining operations. The government has been actively promoting the coal industry, and there have been several major investments in the sector in recent years.",
        articleImg18: "",

        subtitle19: "",
        mediumText19: "",
        paragraph19:
            "One of the significant investments in the Kazakh coal industry is the construction of the Bozshakol coal mine, which is one of the largest coal mines in the world. The project is being developed by Kazakhmys Corporation, a leading mining company in Kazakhstan, and is expected to produce over 4 million tons of coal annually.",
        articleImg19: "",

        subtitle20: "",
        mediumText20: "",
        paragraph20:
            "The government has also been offering various incentives to encourage investment in the coal industry. For instance, the government has provided tax breaks and subsidies to companies investing in coal mining and has established a special economic zone in the Karaganda region to attract foreign investment.",
        articleImg20: "",

        subtitle21: "",
        mediumText21: "",
        paragraph21:
            "Foreign investors have been showing increasing interest in the coal industry of Kazakhstan, with many companies investing in new coal mining projects. China, in particular, has been a significant investor in the Kazakh coal industry, with several joint ventures established between Chinese and Kazakh companies.",
        articleImg21: "",

        subtitle22: "Environmental Concerns and Regulations",
        mediumText22: "",
        paragraph22: "",
        articleImg22: "",

        subtitle23: "",
        mediumText23: "",
        paragraph23:
            "Kazakhstan's coal industry has faced growing concerns over its impact on the environment. Coal mining and burning release significant amounts of pollutants and greenhouse gases, contributing to air and water pollution, soil degradation, and climate change. The environmental challenges posed by the coal industry have become a major issue for the government and the public in recent years, leading to the introduction of stricter regulations and policies.",
        articleImg23: "",

        subtitle24: "",
        mediumText24: "",
        paragraph24:
            "To address the environmental concerns, the Kazakh government has implemented a range of measures to reduce the environmental impact of coal mining and burning. These include stricter emission standards for power plants, the promotion of clean coal technologies, and the development of renewable energy sources. In addition, the government has initiated reforestation and soil rehabilitation projects to restore the damaged land caused by coal mining.",
        articleImg24: "",

        subtitle25: "Future Prospects of the Coal Industry in Kazakhstan",
        mediumText25: "",
        paragraph25:
            "The future of the coal industry in Kazakhstan looks promising. With an increase in global demand for coal, the country is well-positioned to become a major player in the international market. Also, the government of Kazakhstan has implemented policies and initiatives to support the coal industry, including subsidies and tax breaks for coal producers. ",
        articleImg25: "",

        subtitle26: "",
        mediumText26: "",
        paragraph26:
            "However, to remain competitive and sustainable in the long run, the coal industry in Kazakhstan must also address environmental concerns and adopt new technologies to reduce its carbon footprint.",
        articleImg26: "",
    },
    {
        id: 16,
        preview: "images/article-preview-16.webp",
        tag: "world coal",
        name: "Extraction and export of coal in Columbia. Drummond Company",
        paragraph1:
            "In 2019, 84.3 million tonnes of coal were produced in Colombia. In Colombia, only privately owned mining enterprises are involved in the coal mining process. The Department of la Guajira's Carbones del Cerrejón LLC is home to the largest coal mining facility. Anglo American (33%), Glencore International (33%), and BHP Billiton (33%), the company's joint venture partners, produced 31.3 million tonnes in 2008. In the La Guajira Department, it runs the Cerrejon Centro, Cerrejon Sur, Cerrejon Zonoa Norte, and Oreganal mines. According to the U.S. Geological Survey, in 2005, 22% of the company's exports went to North America and 59% went to Europe.",

        subtitle2: "",
        mediumText2: "",
        paragraph2:
            "Another major coal mining company in the country is Drummond Ltd, the second-largest company in the mining business. Its La Loma mine in the Cesar Department recorded a production of 22 million tonnes. The business also runs the El Descanso mine, whose production capacity will be increased from its current 3 million tonnes annually to 25 million tonnes.",
        articleImg2: "",

        subtitle3: "",
        mediumText3: "",
        paragraph3:
            "Despite a rise of 2% from 2009 to 2010, Columbia’s coal production in 2010 fell short of the government's goal of 80 million tons, apparently as a result of exceptionally heavy rainfall in the year's final months. The total amount of coal exported by Colombia in 2010 was 68.14 million tons. According to Colombia's mining minister Carlos Rodado, 144 million tons of coal were to be produced that year.",
        articleImg3: "",

        subtitle4: "",
        mediumText4: "",
        paragraph4:
            "Vale Colombia decided to sell a division of Goldman Sachs for $407 million in 2012 in exchange for the Colombian thermal coal holdings. Included in the sale are Vale's El Hatillo mine, Cerro Largo deposit, port facilities on Colombia's Atlantic coast, and an 8.4% ownership stake in the railway that connects the mines to the port.",
        articleImg4: "",

        subtitle5: "",
        mediumText5: "",
        paragraph5:
            "In Colombia, the government controls all hydrocarbon deposits, and under concession agreements with the government, private corporations run the country's coal mines.",
        articleImg5: "",

        subtitle6: "Coal Exportation in Columbia",
        mediumText6: "",
        paragraph6:
            "Colombia exports a lot of coal, making it a prominent coal exporter. As of 2021, Colombia had 5.24% of the global coal market; the top three countries importing Colombian coal are Chile, Turkey, and the Netherlands. As Europe invests more significantly in renewable energies, MME has concentrated on boosting shipments to Asian nations in the next few years, with China and India being the main anticipated customers for Colombian coal.",
        articleImg6: "",

        subtitle7: "",
        mediumText7: "",
        paragraph7:
            "According to the U.S. Geological Survey's assessment of mining in Colombia, 'based on information provided by the coal producers in Colombia, the Government expects the production of coal to increase to 124.9 mt in 2011 and to 134.2 mt in 2019; the Department of Cesar would see the largest increase in production.' It's anticipated that the whole output of the Cesar and La Guajira Departments would continue to be exported. The government's forecast on coal is a little more optimistic than other analysts.",
        articleImg7: "",

        subtitle8: "",
        mediumText8: "",
        paragraph8:
            "Coal production in Colombia, according to mining minister Carlos Rodado, will exceed 100 million tons by 2015 and 144 million tons by 2020.",
        articleImg8: "",

        subtitle9: "About Drummond",
        mediumText9: "",
        paragraph9:
            "Drummond now exports over 30 million metric tons of coal and has control over more than two billion tons of reserves, making it one of the world's top producers of the fuel. A leader is someone who is unwavering in their dedication to giving their customers the greatest possible quality and service. Drummond, which also produces coal, is a well-known brand in the foundry sector for its high-quality goods and consistent supply. Drummond is also the country's largest manufacturer of merchant coke.",
        articleImg9: "",

        subtitle10: "",
        mediumText10: "",
        paragraph10:
            "At every level of this firm, on every project, and every day, we are dedicated to our customers, workers, the local communities in which we operate, and the environment at large.",
        articleImg10: "",

        subtitle11: "Drummond influence In Columbia Coal ",
        mediumText11: "",
        paragraph11: "",
        articleImg11: "/images/article-16-img-1.webp",

        subtitle12: "",
        mediumText12: "",
        paragraph12:
            "Since 1995, we have owned and run mines near El Paso, La Jagua de Ibirico, Chiriguaná (also known as Mina Pribbenow), and the La Loma coal project. Additionally, Drummond Ltd. collaborated with the regional Colombian government to construct a fully operational international port and upgrade the train infrastructure. We currently own and manage reserves at La Loma, El Descanso, Rincn Hondo, Similoa, and Cerro Largo totaling over 2 billion tons, with the last three undergoing environmental licensing. We delivered around 23 million tons of coal to clients in more than 30 nations in 2011.",
        articleImg12: "",

        subtitle13: "",
        mediumText13: "",
        paragraph13:
            "Our dedication to Colombia is unwavering because our business is centered there. At Drummond Ltd., we think about how our decisions will affect our people, our communities, and the environment every day, on every project, and at every level. We work hard to raise the education, health, and general wellbeing of our staff members and their families. We make significant investments in neighborhood improvement projects, maintain tight ties with the local authorities, and create and carry out programs for the advancement of and benefit to our neighborhoods. As we create and put into practice cutting-edge sustainability approaches, we continuously work to manage and reduce the effects of coal mining, transportation, and shipment.",
        articleImg13: "",

        subtitle14: "",
        mediumText14: "",
        paragraph14:
            "Drummond, which is devoted to preserving and enhancing the wellbeing of its citizens, is getting ready to help the nation's economic recovery in 2022 and make sure that competitiveness translates into a higher standard of living for the towns within its sphere of influence.",
        articleImg14: "",

        subtitle15: "Conclusion",
        mediumText15: "",
        paragraph15:
            "Columbia is responsible for about 80% of the coal on the continent and has the second biggest reserves after Brazil. The country partners with private companies in the distribution of coal to serve the nation. A major contributor is Drummond Ltd. The company is responsible for up to half of the country's coal production. Their dedication and commitment to the country is highly recognized and has played a huge role in the overall economy. ",
        articleImg15: "",
    },
    {
        id: 17,
        preview: "images/article-preview-17.webp",
        tag: "top countries",
        name: "Global leaders in coking coal export and production",
        articleImg1: "",
        paragraph1:
            "Coking coal, also known as metallurgical coal is a type of coal that can be refined and used to synthesize a topnotch quality coke. Coke functions as a vital reactant and fuel in the blast furnace process for primary steel making. Coking coal and steel are directly related, such that the demand for the former directly affects the demand for the latter.  Studies show that coal is one of the cheapest sources of power in many developing countries. It is cheaper and more accessible than oil and gas. ",

        subtitle2: "",
        mediumText2: "",
        paragraph2:
            "According to the International Energy Agency, coal plays a significant role in the growth and development of various countries. It is mined in more than 50 countries and utilized for power generation in over 70 countries. Over the years, the global market for coal has become highly flexible and unstable. This instability is due to the wide variety of qualities that are traded and new price indexes for different versions in several parts of the world. There are four stages involved in formation of coal: Peat, lignite, bituminous, and zinc anthracite. You must go through each process to complete the formation of coal.  ",
        articleImg2: "",

        subtitle3: "",
        mediumText3: "",
        paragraph3:
            "According to the IEA, the largest coal exporting countries are Australia and Indonesia, and the largest coal importing countries are India and Japan. The cost of coal transportation significantly affects the total delivered price of coal in the global market. For this reason, international trade in coal can be grouped into two regional markets. Coal is used in many parts of the as a fuel for power plants providing electricity. It is also used to produce steel, create liquid fuel, and manufacture cement. According to the IEA, Australia, Indonesia, and Russia all contribute about 70% to the global coal exports.",
        articleImg3: "",

        subtitle4: "Coking Coal Production and Exportation in Australia",
        mediumText4: "",
        paragraph4:
            "Austria is one of the largest exporters of coal in the global market. In 2016, Australia was the biggest exporter and recorded about 2.9 billion dollars, representing 40.3% of the total work output. Coal is the second most exported commodity in the country and it accounts for about one-half of energy export income. Over the years, coal production has increased and it continues to contribute significantly to investment in the export sector. Between 2000 and 2010, it’s production increased by 33% and local consumption reduced by 15%. The table below shows the countries that were at the forefront of coal exporting in 2016.",
        articleImg4: "",

        table4Columns4: {
            tableHeader1: "S/N",
            tableHeader2: "Country",
            tableHeader3: "Value (USD Million)",
            tableHeader4: "Value (%)",
            tableItem1: "1.",
            tableItem2: "2.",
            tableItem3: "3.",
            tableItem4: "4.",
            tableItem11: "Australia",
            tableItem12: "Indonesia",
            tableItem13: "Russia",
            tableItem14: "United States",
            tableItem21: "29579",
            tableItem22: "12898",
            tableItem23: "8907",
            tableItem24: "4336",
            tableItem31: "40.3",
            tableItem32: "17.6",
            tableItem33: "12.1",
            tableItem34: "5.9",
        },

        subtitle5: "Coking Coal production and exportation in Indonesia",
        mediumText5: "",
        paragraph5:
            "According to the International Energy Agency, Indonesia comes second on the list of biggest coal exporters to the global market. The county exported coal worth 1.2 billion dollars in 2016, representing 17.6 of the total global export. Between 2015 and 2016, the coal export in the country declined by 12%. It is one of the most exported resources in the country. The country’s major trade markets include China and Japan. They both purchase over half of the coal produced in Indonesia. ",
        articleImg5: "",

        subtitle6: "Coking Coal production and exportation in Russia",
        mediumText6: "",
        paragraph6: "",
        articleImg6: "/images/article-17-img-1.webp",

        subtitle7: "",
        mediumText7: "",
        paragraph7:
            "Between 2012 and 2016, the coal import rate in Russia increased by 9%. The International Energy Agency recorded about 8.9 billion dollars worth of coal exports in Russia in 2016. This value accounted for 12.1% of the global coal export sales. The country’s major trade partners include China, Turkey, Japan, and Korea. ",
        articleImg7: "",

        subtitle8:
            "Coking Coal production and exportation in The United States",
        mediumText8: "",
        paragraph8:
            "According to the International Energy Agency, in 2018, the United States was the second-largest coal producing country in the world, producing over 702 million tons yearly. This accounted for roughly 9% of the global coal production. Studies show that it is the third-largest coal consumer in the world. Its coking coal consumption is estimated at 8.5% of the world’s total consumption. Approximately 23.5% of the country’s electricity is generated by coal, making it the world’s biggest reserve for all types, including coking coal. ",
        articleImg8: "",

        subtitle9: "How much does coking coal cost?",
        mediumText9: "",
        paragraph9:
            "Coking coal is not always made up of the same materials. Its quality depends on various factors, including its method of production. The highest grade of coking coal is usually more expensive than high-volatile or semi-soft coking coal. Other factors that affect the price of coal include insurance, freight, and if the price is related to spot price or contracted goal. ",
        articleImg9: "",

        subtitle10: "",
        mediumText10: "",
        paragraph10:
            "Coking coal is highly sought after in different parts of the world including Europe and Asia. Its price in these parts (Europe and Asia) is usually determined by the rate of supply and demand. ",
        articleImg10: "",

        subtitle11: "Problems associated with Coking Coal",
        mediumText11: "",
        paragraph11:
            "According to the “Critical Raw Materials for the EU,” Australia and China are the main suppliers of coking coal. The IEA suggests that the amount of coals produced in these countries increases their risks of experiencing certain consequences. For example, the Austrian coking coal supply was caught off by flooding in 2011. In addition, coking coal contributes significantly to economic development because it is used in the steel sector and the absence of appropriate options.  ",
        articleImg11: "",

        subtitle12: "World Coal Association",
        mediumText12: "",
        paragraph12:
            "The World Coal Association (WCA) consists of a network of coal industries of leading coal trading countries. The network is formed by essential international coal producers and stakeholders. Its major aim is to enforce rules that guide coal production and demonstrate the contributions of coal in attaining lower carbon energy future. The association provides membership for companies, not profit entities that have a stake in the future of coal from any part of the world. WCA promotes lobbying, offers coal info to law makers in the International Energy Agency, and organizes workshops. The association also facilitates clean coal technologies. ",
        articleImg12: "",

        subtitle13: "Conclusion",
        mediumText13: "",
        paragraph13:
            "Coking coal is referred to as a borderline case. This is because it does not have all the criteria that meets the economic importance threshold. However for the sake of safety, the International Energy Agency considers it as an essential raw material for development. The EU plays a significant role in providing safe, sustainable, and reliable access to coking coal for economic development and growth. ",
        articleImg13: "",
    },
    {
        id: 18,
        preview: "images/article-preview-18.webp",
        tag: "analysis",
        name: "How to use UREA-46 fertilizers",
        articleImg1: "",
        paragraph1:
            "Urea fertilizer is the most common nitrogenous fertilizer in the agricultural industry. It is commonly used in crops and as a supplement in cattle feed. The primary purpose of the urea-46 fertilizer is to provide high levels of nitrogen to plants. When you apply urea to the soil, a large amount of ammonia is usually lost due to the hydrolysis of ammonium carbonate. For this reason, experts always advise people to take the right precautions when applying urea to the soil. Studies show that hydrolysis of urea causes severe damage to the seedlings. The damage is usually heightened when the fertilizer is applied in excess. You can apply urease inhibitors to prevent the hydrolysis urea when applying the fertilizer to the soil.",

        subtitle2: "",
        mediumText2: "",
        paragraph2:
            "Urea-46 is one of the most consumed agricultural fertilizers in the world. It is used in various countries to improve the quality and quantity of crop life. ",
        articleImg2: "",

        tableLarge2: {
            tableHeader1: "S/N",
            tableHeader2: "Countries",
            tableHeader3: "Consumption in million metric tons of nutrients",
            tableItem1: "1.",
            tableItem2: "2.",
            tableItem3: "3.",
            tableItem4: "4.",
            tableItem5: "5.",
            tableItem6: "6.",
            tableItem7: "7.",
            tableItem8: "8.",
            tableItem9: "9.",
            tableItem10: "10.",
            tableItem11: "11.",
            tableItem12: "12.",
            tableItem13: "13.",
            tableItem14: "China",
            tableItem15: "India",
            tableItem16: "United States",
            tableItem17: "Brazil",
            tableItem18: "Indonesia",
            tableItem19: "Canada",
            tableItem20: "Pakistan",
            tableItem21: "Russia",
            tableItem22: "France",
            tableItem23: "Vietnam",
            tableItem24: "Bangladesh",
            tableItem25: "Australia",
            tableItem26: "Turkey",
            tableItem27: "45.4",
            tableItem28: "29.37",
            tableItem29: "20.36",
            tableItem30: "15.88",
            tableItem31: "6.36",
            tableItem32: "4.79",
            tableItem33: "4.55",
            tableItem34: "4.08",
            tableItem35: "2.99",
            tableItem36: "2.77",
            tableItem37: "2.56",
            tableItem38: "2.53",
            tableItem39: "2.48",
        },

        subtitle3: "Features of Urea-46 Fertilizer",
        mediumText3: "",
        paragraph3:
            "Urea is one of the most popular types of fertilizer. Its adaptability gives it an edge over other fertilizers. Urea-46 has the ability to adapt to any type of soil. It is primarily used in agriculture as a fertilizer or animal feed. Urea ammonium nitrate contains a high quantity of nitrogen. It is often combined with both pesticides and insecticides to kill foreign elements in a soil. The fertilizer is chemically modified to prevent the loss of nitrogenous compounds in the atmosphere. So, the nitrogen content in the air remains the same even when the urea degrades in the soil. To maintain a constant air nitrogen content, experts also recommend converting the urea into formaldehyde which is converted into ammonia at a rate that complements the plant’s development. ",
        articleImg3: "/images/article-18-img-1.webp",

        subtitle4: "What are the pros of Using Urea-46 Fertilizer? ",
        mediumText4: "",
        paragraph4:
            "Urea-46 fertilizer plays a significant role in the development and growth of plants. It is responsible for the leafy and lush appearance of most plants. It also facilitates photosynthesis in some plants. Ammonia,  a major constituent of urea, is usually released into the soil when you apply urea. This can result in various harmful effects on the plants due to the high PH of ammonia. Studies show that the fertilizer is most ideal for soils with sufficient moisture content, because ammonia reacts quickly with moisture. Experts recommend using ammonium nitrate in soils that lack adequate moisture content. ",
        articleImg4: "",

        subtitle5: "",
        mediumText5: "",
        paragraph5:
            "You can apply Urea-46 to a plant to control seed germination. The fertilizer can be combined with phosphorus and potassium to produce a perfect fertilizer. Ensure you release the nitrogen inside the urea for plants to consume. ",
        articleImg5: "",

        subtitle6: "",
        mediumText6: "",
        paragraph6:
            "You can produce ammonia converting the urea into bicarbonate and ammonium ions. The process of oxidizing ammonia is called nitrification. Nitrite-oxidizing bacteria helps oxidize nitrite to nitrates. The process of converting the fertilizer to ammonia and nitrogen is highly important because plants absorb nitrogen faster when it is in the form of nitrate and ammonium. Experts recommend regulating acidity by adding compounds like calcium carbonate to the fertilizer before applying. ",
        articleImg6: "",

        subtitle7: "",
        mediumText7: "TOP UREA PRODUCING COUNTRIES IN 2016",
        paragraph7: "",
        articleImg7: "",

        subtitle8: "What are the cons of Using Urea-46 fertilizer?",
        mediumText8: "",
        paragraph8:
            "One disadvantage of Urea-46 fertilizer is that it facilitates the growth of green algae. The growth of green algae suffocates reservoirs when it accumulates in the soil. Experts recommend applying urea in moderate quantities to reduce the risk of green algae. ",
        articleImg8: "",

        subtitle9: "",
        mediumText9: "",
        paragraph9: "Other cons of Urea-46 fertilizer include:",
        articleImg9: "",

        subtitle10: "",
        mediumText10: "High Alkalinity",
        paragraph10:
            "Urea has a PH greater than 7. It also has a high alkalinity rating on its NPK rating card. Studies show that its alkalinity is not as high as other elemental forms of nitrogen used in the agricultural sector, but it can cause some serious damage to plants. However, its high alkalinity allows it to react faster in the soil and cause positive changes quickly. ",
        articleImg10: "",

        subtitle11: "",
        mediumText11: "",
        paragraph11:
            "The high alkalinity of Urea-46 can have positive effects on clay soils because there is usually no chance of slow-release fertilizers working unless a small amount of water carries them into a temporary hole. ",
        articleImg11: "",

        subtitle12: "",
        mediumText12: "Releases Nutrients Too Quickly",
        paragraph12:
            "Urea-46 contains high levels of nitrogen and it produces fertilizer anytime it is exposed to water. Studies show that it releases more than half of its nitrogen immediately when it is applied to a wet soil, and its remaining contents degrade gradually. The rate at which urea releases its nutrients depends on the soil type. For example, urea releases most of its nutrients within 24 hours in sandy soils that don’t have enough water mobility. In heavy-clay soil, it may take a bit longer for the fertilizer to release its nutrients. This is usually because it is more difficult for Lahr particles to move through heavy-clay soils.",
        articleImg12: "",

        subtitle13: "Consequences of using excess Urea",
        mediumText13: "",
        paragraph13:
            "Aside from its positive effects on soil growth, Urea-46 may cause some harmful effects when mixed with water meant for human consumption. This is why experts always recommend using urea fertilizers. They are environment-friendly and cost-effective. To get the best out of the fertilizer, you must ensure it is applied in the right quantity. Excess use of urea can negatively affect the environment. Studies show that excess levels of urea can impede or prevent seed growth.",
        articleImg13: "",

        subtitle14: "",
        mediumText14: "",
        paragraph14:
            "As an organic fertilizer, urea helps improve the quality of soil and provide nitrogen to the plants. Urea-46 fertilizer also helps improve the quantity of crop yields. It supplies essential nutrients to boost the growing process of plants. If it is not used appropriately, it can impede seed germination and plant growth. It would help to understand how to use the fertilizer to avoid any harmful effects. ",
        articleImg14: "",

        subtitle15: "What are the primary functions of Urea-46 fertilizer?",
        mediumText15: "",
        paragraph15:
            "The primary function of Urea-46 fertilizer is to supply the necessary nutrients to plants and promote green leafy growth. Aside from its effects on plants, urea occurs naturally in the body and helps convert proteins into amino acids. The amino acids are then used to synthesize new proteins. ",
        articleImg15: "",

        subtitle16: "",
        mediumText16: "",
        paragraph16:
            "Urea supplies nitrogen for amino acid synthesis when the roots are deprived of nitrogen from other sources, such as nitrates. You may experience various difficulties when trying to add fertilizer to an unfriendly soil column. You can add a small amount of water to the soil at the right time to move unwilling soil columns, such as clay soils. The right amount of water will create temporary holes and help move large particles through solid soils. ",
        articleImg16: "",

        subtitle17: "Conclusion",
        mediumText17: "",
        paragraph17:
            "Urea-46 is one of the most effective fertilizers in the agricultural sector. It contains high amounts of nitrogen which helps improve the growth and development of plants. It can cause some serious damage when applied in excess. Urea-46 supplies nitrogen to soil by acting as an organic fertilizer. Since it contains water-insoluble substances, it can release its nutrients gradually without damaging the roots. In most cases, farmers use the fertilizer with a water-insoluble compound to produce the right fertilizer. ",
        articleImg17: "",
    },
    {
        id: 19,
        preview: "images/article-preview-19.webp",
        tag: "analysis",
        name: "MOP: a type of potash fertilizer",
        articleImg1: "",
        paragraph1:
            "There are two major types of potassium fertilizers that combine K+ with either chloride (Muriate of potash) or sulfate ( sulfate of potash). Muriate of potash, also known as potassium chloride, is available in three different varieties: 50% K, 41% K, and 33% K. The last two grades are made up of significant amounts of sodium chloride (NaCl) and are used as K+ fertilizer for natrophilic crops. MOP is the most common type of Potash. It contains 60% of potash. It provides all the necessary nutrients needed for crop growth and development. It supplies plants with proteins and sugars, protects crops against dryness, and improves water retention in plants. MOP facilitates photosynthesis and helps maintain the structure and strength of leaves. ",

        subtitle2: "",
        mediumText2: "",
        paragraph2:
            "Muriate of potash is the most popular potassium source used in agriculture. It accounts for almost 95% of all the potash fertilizers used globally for improving the quality of plants. It has one of the highest nutrient concentrations and has a competitive value with other grades of potassium. Muriate of potash (MOP) has a composition of 0.5% water soluble potash by weight, 60% sodium as NaCl by weight ( mostly in dry conditions). Examples of agricultural products that contain MOP include tak agro from Ukraine, sugar cane, vegetables, fruit trees, and other field crops. It is usually pink in color with a size of 2-4mm. ",
        articleImg2: "",

        subtitle3: "What are the benefits of Muriate of potash (MOP)?",
        mediumText3: "",
        paragraph3: "",
        articleImg3: "",

        list3: {
            listItem1: "● The type and quality of the ore",
            listItem2: "● The depth and location of the deposit",
            listItem3: "● The size and shape of the deposit",
            listItem4: "● The environmental impact of the mining method",
            listItem5: "● The cost and feasibility of the mining method",
            listItem6:
                "● The safety and health risks associated with the mining method",
            listItem7: "● The availability and cost of labor and equipment",
        },

        subtitle4: "",
        mediumText4: "",
        paragraph4:
            "Muriate of Potash (MOP) is a type of potash fertilizer which contains high levels of potassium. Its primary purpose is to treat potassium deficiency disease in plants or soil. Aside from having potassium, it also has moderate quantities of chloride and a bulk density of 1.05 tonnes per cubic meter.",
        articleImg4: "",

        subtitle5: "Chemical Composition of Muriate of Potash Fertilizer (MOP)",
        mediumText5: "",
        paragraph5:
            "To learn how MOP works, It is important to first understand its chemical composition. The chemical properties of MOP include:",
        articleImg5: "",

        tableLarge5: {
            tableItem1: "1.",
            tableItem2: "2.",
            tableItem3: "3.",
            tableItem4: "4.",
            tableItem11: "Potassium content",
            tableItem12: "Chloride content",
            tableItem13: "PH",
            tableItem14: "Fertilizer analysis",
            tableItem21: "50%",
            tableItem22: "45%",
            tableItem23: "7",
            tableItem24: "0-0-60",
        },

        subtitle6: "",
        mediumText6: "",
        paragraph6:
            "You can apply potassium chloride fertilizer to vegetable crops, such as cucumber, egg plants, watermelon, kale, lettuce, cucumber, and pepper. MOP can also be used on tree and fruit crops, such as orange, mango, apple, avocado, cashew, and oil palm. The fertilizer improves the quality of broad acre crops, such as sorghum, millet, sesame, maize, and sugarcane. ",
        articleImg6: "",

        subtitle7: "",
        mediumText7: "",
        paragraph7:
            "Let’s take a look at the benefits of the two major components of Muriate of Potash ( MOP) fertilizer.",
        articleImg7: "",

        subtitle8: "Benefits of Potassium",
        mediumText8: "",
        paragraph8: "",
        articleImg8: "",

        list8: {
            listItem1:
                "● Potassium enhances the movement of nutrients, carbohydrates, and water in plants.",
            listItem2:
                "● It boosts the taste, shelf life, quality, and appearance of fruits. ",
            listItem3:
                "● Potassium improves the turgidity of fruits and plants by improving water retention. ",
            listItem4:
                "● Potassium increases plant resistance to extreme weather conditions, pests, and diseases. ",
            listItem5: "● It helps control the exchange of CO2 ",
            listItem6:
                "● Potassium facilitates the process of photosynthesis. ",
            listItem7: "● Potassium aids the development of roots.",
        },

        subtitle9: "Benefits of Chloride",
        mediumText9: "",
        paragraph9: "",
        articleImg9: "",

        list9: {
            listItem1:
                "● Chloride helps plants develop in the right way when used appropriately. ",
        },

        subtitle10: "How to use MOP fertilizer",
        mediumText10: "",
        paragraph10:
            "You can combine Muriate of Potash with other types of fertilizers before applying it as basal to plants. You can use the MOP fertilizer as a side dress or top dress. You can side dress MOP fertilizer by burying it in holes beside every plant. This will allow accurate spread and help the plant utilize the fertilize maximally. You can also fertigate MOP fertilizer to plants via drip irrigation. Experts recommend applying 1-5KG of Muriate of Potash to one hectare of farmland. The exact amount of potassium chloride to be applied depends on the type of crop and fertility of the soil. It would help to be careful when applying MOP fertilizer to soils that contain high levels of chloride. ",
        articleImg10: "",

        subtitle11:
            "What are the pros of using Muriate of Potash Fertilizer (MOP)",
        mediumText11: "",
        paragraph11: "",
        articleImg11: "",

        list11: {
            listItem1:
                "● MOP fertilizer is cheaper than other potash fertilizers. ",
            listItem2:
                "● It contains potassium that helps treat different types of disease and increase resistance to extreme weather.",
            listItem3:
                "● MOP boosts the color, taste, quality, shelf life, of fruits.",
            listItem4: "● It is easy to store and use",
        },

        subtitle12:
            "What are the cons of using Muriate of Potash Fertilizer (MOP)?",
        mediumText12: "",
        paragraph12:
            "Before applying Muriate of Potash fertilizer to your plant, there are certain factors that you must consider. For example, it can create a toxic environment when applied to plants that contain high levels of chloride. It can also create an imbalance in plant nutrients due to its moderate level of chloride. For this reason, experts always recommend monitoring the levels of MOP before applying to a plant. It is also important to check the chloride level of a crop before applying the fertilizer.",
        articleImg12: "",

        subtitle13: "Differences between MOP and SOP",
        mediumText13: "",
        paragraph13: "",
        articleImg13: "",

        subtitle14: "",
        mediumText14: "",
        paragraph14:
            "SOP, also known as sulphate potash, is the second most popular type of potash fertilizer in the agricultural industries after MOP. While they are both used to improve the quality of plants and crops, they also contain unique properties that distinguish them from each other.  ",
        articleImg14: "",

        tableLarge14: {
            tableHeader1: "S/N",
            tableHeader2: "SOP",
            tableHeader3: "MOP",
            tableItem1: "1.",
            tableItem2: "2.",
            tableItem3: "3.",
            tableItem4: "4.",
            tableItem11: "SOP is made up of potassium and sulphur.",
            tableItem12:
                "SOP does not occur naturally. It is usually produced via chemical processes.",
            tableItem13: "SOP is more expensive and rare.",
            tableItem14:
                "SOP can be applied to plants that are sensitive to chloride. ",
            tableItem21: "MOP is made up of potassium and chloride ",
            tableItem22: "MOP is a naturally occurring fertilizer ",
            tableItem23: "MOP is cheaper and easy to access",
            tableItem24:
                "MOP is not ideal for plants that are sensitive to chloride. ",
        },

        subtitle15: "Conclusion",
        mediumText15: "",
        paragraph15:
            "Muriate of potash is one of the cheapest sources of Potash (K). It is usually applied as a primary dressing fertilizer for crops that do not have high sensitivity. It can also be used in mixing basal bulk blends for crops that are not salt sensitive. MOP may be a vital agricultural product for crop development, but it is not ideal for every crop. For example, it should not be used as a top dressing for crops like tobacco, potatoes, and tomatoes because it contains high levels of chloride.",
        articleImg15: "",
    },
    {
        id: 20,
        preview: "images/article-preview-20.webp",
        tag: "top countries",
        name: "Potash fertilizers (MOP). Top - 10 manufacturers of the planet",
        articleImg1: "",
        paragraph1:
            "Potash is a vital resource that is used in the production of several goods and chemicals, such as water softeners, detergents, and even glass, but the main market for potash is still the fertilizer business. And as researchers delve deeper into the intricate inner workings of ideal crop production, discoveries show that potash is even more important than we ever thought, influencing crop yield, quality, and even a crop's capacity to withstand stress. ",

        subtitle2: "",
        mediumText2: "",
        paragraph2:
            "In order to meet the rising needs for proteins, food crops, and biofuels, potash fertilizers have gradually emerged from beneath the cover of phosphate and nitrogen.",
        articleImg2: "",

        subtitle3: "",
        mediumText3: "",
        paragraph3:
            "Because of the great use of potash, many countries manufacture it. However, the destruction of demand in the second half of 2022 was ultimately brought on by the fertilizer's growing prices. Economic sanctions on Russia and Belarus had an impact on the global potash supply, which led to higher prices and less usage. According to estimates, the amount of potash used globally for fertilizers in 2022 will drop from 40.6 million tons to 35 to 39 million tons. This article provides a list of top ten manufacturers of potash fertilizers. ",
        articleImg3: "",

        tableLarge3: {
            tableHeader1: "S/N",
            tableHeader2: "Countries",
            tableHeader3: "Data",
            tableItem1: "1",
            tableItem2: "2",
            tableItem3: "3",
            tableItem4: "4",
            tableItem5: "5",
            tableItem6: "6",
            tableItem7: "7",
            tableItem8: "8",
            tableItem9: "9",
            tableItem10: "10",
            tableItem11: "Canada",
            tableItem12: "China",
            tableItem13: "Russia",
            tableItem14: "Belarus",
            tableItem15: "Germany",
            tableItem16: "Israel",
            tableItem17: "Jordan",
            tableItem18: "Chile",
            tableItem19: "Laos",
            tableItem20: "Spain",
            tableItem21: "16 million MT",
            tableItem22: "6 million MT",
            tableItem23: "5 million MT",
            tableItem24: "3 million MT",
            tableItem25: "2.8 million MT",
            tableItem26: "2.5 million MT",
            tableItem27: "1.7 million MT",
            tableItem28: "850,000 MT",
            tableItem29: "600,000 MT",
            tableItem30: "450,000 MY",
        },

        subtitle4: "1. Canada",
        mediumText4: "",
        paragraph4: "",
        articleImg4: "",

        subtitle5: "",
        mediumText5: "",
        paragraph5:
            "Canada is at the top of the list of nations that produce the most potash. The country will produce 180,000 MT more potash in 2022 than it did in 2021, making it the greatest potash producer in the world. ",
        articleImg5: "",

        subtitle6: "",
        mediumText6: "",
        paragraph6:
            "The largest potash firm in the world, Nutrien, is headquartered in Saskatchewan, a province in the Canadian prairies. Potash Corporation of Saskatchewan and Agrium, two crop nutrient firms, merged in 2018, giving rise to it. The transaction led to the creation of 'a worldwide agriculture gian' today worth more than US$41 billion.",
        articleImg6: "",

        subtitle7: "2. China ",
        mediumText7: "",
        paragraph7: "",
        articleImg7: "",

        subtitle8: "",
        mediumText8: "",
        paragraph8:
            "Coming second on the list is China. China's potash output was unchanged in 2022 from 2021's output, remaining flat. Nonetheless, due to a decline in production in Russia and Belarus, the nation nevertheless moved up to the second position on the list of major potash-producing nations. ",
        articleImg8: "",

        subtitle9: "",
        mediumText9: "",
        paragraph9:
            "China is the world's largest consumer of potash, making up around 20% of global consumption, making potash an essential resource for the nation. China is dependent on potash imports, particularly when it comes to MOP, as domestic demand for the substance is larger than domestic supply.",
        articleImg9: "",

        subtitle10: "3. Russia",
        mediumText10: "",
        paragraph10:
            "Russia produced 5 million MT of potash in 2022, a sharp decline of 45 percent compared to the 9.1 million MT produced in the previous year. In addition to being one of the top producers of potash worldwide, Uralkali is the top potash company in Russia and supplied around 20% of the pre-war world market. The company operates five mines and seven ore processing and mills.",
        articleImg10: "",

        subtitle11: "",
        mediumText11: "",
        paragraph11:
            "Due to US sanctions there was a limit in the amount of potash that Russia could import. The European Union also imposed import quotas on potash from Russia in reaction to Moscow's invasion of Ukraine. For Russia's survival they also had to halt fertilizer exports to nations it regarded as hostile. Russia continued to send goods to China, India, and a few African and South American nations, but its exports fell by roughly 30% in 2022. ",
        articleImg11: "",

        subtitle12: "4. Belarus",
        mediumText12: "",
        paragraph12: "",
        articleImg12: "",

        subtitle13: "",
        mediumText13: "",
        paragraph13:
            "A total of 3 million MT of potash was produced in Belarus in 2022, an almost 61 percent decrease from 2021 levels. The country's productivity has been rising since 2016 until Russia’s invasion of the Eastern European nation. Belarus support and alliance with Russia over the conflict with Ukraine has placed its potash sector in a similar predicament. ",
        articleImg13: "",

        subtitle14: "5. Germany",
        mediumText14: "",
        paragraph14:
            "Despite producing 3 million MT of potash in 2019, Germany's production of the substance has decreased since then and was only about 2.8 million MT in 2021 and 2022. The country’s biggest potash miners company, K+S (ETR:SDF) plays a huge role in its growth in the sector. It has a number of projects, as well as about six mines in three different regions in the nation. ",
        articleImg14: "",

        subtitle15: "6. Israel",
        mediumText15: "",
        paragraph15:
            "Israel, since 2017 has stayed in the annual potash production range of 2 million to 2.5 million MT. The nation ranks sixth in the country that produces the most potash, it is also host to the sixth largest potash producer company (NYSE:ICL,TLV:ICL). ",
        articleImg15: "",

        subtitle16: "",
        mediumText16: "",
        paragraph16:
            "A third of the world's bromine is also produced by this company. Which is frequently collected from the same salt water and brine reserves that are used to make potash.",
        articleImg16: "",

        subtitle17: "7. Jordan",
        mediumText17: "",
        paragraph17:
            "The only producer of potash in the Arab world is the Arab Potash Corporation, which is based in Jordan and ranks eighth in terms of volume. Although there was a decline in the production of potash between 2021 to 2022, in Jordan. Jordan still remains a major supplier of potash to Asia and India as a result of this. From the Dead Sea, potash is extracted by both Israel and Jordan.",
        articleImg17: "",

        subtitle18: "8. Chile",
        mediumText18: "",
        paragraph18:
            "In 2018, Chile produced 1.2 million MT of potash, the same amount as the previous two years. If only it had at least maintained that number, however, there was  a massive decrease of below 1 million MT in 2019 and it has continued to go down since then. There is still hope for things to get stable, since the production in 2022 was only 8,000 MT less than the year before. ",
        articleImg18: "",

        subtitle19: "",
        mediumText19: "",
        paragraph19:
            "SQM (NYSE:SQM), a significant producer of both lithium and potash, is one of the nation's biggest potash producers and contributes greatly to the growth of the sector. ",
        articleImg19: "",

        subtitle20: "9. Laos",
        mediumText20: "",
        paragraph20:
            "Laos, a country in Southeast Asia, overtook the US to become the ninth-largest producer of potash in 2022. Last year, the nation produced 600,000 MT of potash, a considerable increase over the 260,000 MT of potash produced the year before. Laos has 75 million MT worth of potash deposits. ",
        articleImg20: "",

        subtitle21: "10. Spain",
        mediumText21: "",
        paragraph21:
            "The Spanish potash output increased by 85,000 MT from its level in 2021 to maintain its ranking as the tenth largest potash-producing nation in 2022. Although this number is lower than the 670,000 MT of potash the nation produced in 2016. Spain has 68 million MT worth of potash deposits. ",
        articleImg21: "",
    },
    {
        id: 21,
        preview: "images/article-preview-21.webp",
        tag: "top countries",
        name: "Top 10 coal transshipment ports ",
        articleImg1: "",
        paragraph1:
            "75 percent or thereabouts of US coal exports have historically been handled at the Port of Hampton Roads. Together with the ports of Mobile, New Orleans, and Philadelphia, the Port of Baltimore has consistently ranked second in the 20-percent range. The discussion will focus on fresh development plans as it goes through each of these facilities individually. ",

        subtitle2: "",
        mediumText2: "",
        paragraph2:
            "There have been a number of significant suggestions for developing new projects at ports that have never before handled coal, renovating existing terminals, and building terminals at ports that currently export coal. It is less certain whether plans for significant growth or the construction of wholly new facilities elsewhere, like those suggested for the New York/New Jersey region, will be implemented. This article provides a list of top 10 coal transshipment port. ",
        articleImg2: "",

        subtitle3: "1. Port of Philadelphia",
        mediumText3: "",
        paragraph3:
            "One operational coal terminal currently provides service to the Port of Philadelphia. The Delaware River's Greenwich Point, which is close to the Philadelphia naval yards, is where Pier 124 is situated. Conrail is the owner and operator of the pier, which has space for ships on its south side. It is furnished with mechanical conveyors, trimmers, telescopic chutes, and two rotary vehicle dumpers. On the pier's north side, barges can be loaded. A 40-foot channel provides service to it. ",
        articleImg3: "",

        subtitle4: "",
        mediumText4: "",
        paragraph4:
            "The pier will be upgraded to allow for simultaneous loading of two boats, according to development plans. After phase I development, capacity is predicted to reach 3 mmt/yr, and if all development plans are carried out, it may potentially reach 10 mmt/yr.",
        articleImg4: "",

        subtitle5: "2. Port of Mobile",
        mediumText5: "",
        paragraph5:
            "The port of Mobile is situated at the confluence of the head of Mobile Bay and Mobile River, in the southwest corner of Alabama.  The port is located 170 nautical miles west of New Orleans and about 28 nautical miles north of the bay's entrance into the Gulf of Mexico. The bottom 5 miles of the Mobile River are home to the port's main waterfront facilities. ",
        articleImg5: "",

        subtitle6: "",
        mediumText6: "",
        paragraph6:
            "The deepwater canal that extends from the mouth of the Mobile River makes up Mobile's outer harbor. The Arlington channel extends northwest from the upper extent of the Mobile Bay channel to a turning basin at the southwest corner of Garrows Bend.",
        articleImg6: "",

        subtitle7: "3. Great Lakes Ports",
        mediumText7: "",
        paragraph7:
            "The U.S. Great Lakes coal-loading ports are typically controlled by railroads and have historically catered to domestic and American-Canadian coal traffic. A four-ship feeder service from Conneaut, Ohio to Quebec was examined in a recent U.S. Maritime Administration study. ",
        articleImg7: "",

        subtitle8: "",
        mediumText8: "",
        paragraph8:
            "There are seven U.S. ports on the Great Lakes that are equipped to handle coal shipments for either export or domestic consumption. These cities are Ashtabula, Ohio, Conneaut, Ohio, Erie, Pennsylvania, Sandusky, Ohio, South Chicago, Illinois, Superior, Wisconsin, and Toledo, Ohio.",
        articleImg8: "",

        subtitle9: "4. Port of New Orleans",
        mediumText9: "",
        paragraph9:
            "Presently, two terminals in Plaquemines Parish, which are part of the Port of New Orleans, handle coal destined for export. The first coal exports were handled in 1978 at the International Marine Terminals, Inc. (IMT) facility, which is situated 50 miles below New Orleans. ",
        articleImg9: "",

        subtitle10: "",
        mediumText10: "",
        paragraph10:
            "The Electro-Coal Transfer Terminal is the second export facility that is currently in use in the New Orleans area. There have been various ideas for new export terminals in addition to the ones that already exist.",
        articleImg10: "",

        subtitle11: "5. Port of New York",
        mediumText11: "",
        paragraph11:
            "The development of handling facilities for coal export at the Port of New York has been proposed in a number of ways. The two main plans are focused on Arthur Kill and the lower Hudson River's Ambrose Channel. The Arthur Kill project is a temporary fix intended to redirect part of the coal activity from Hampton Roads and Baltimore to New York. According to the plan, coal will be delivered to Conrail's Port Reading coal terminal and loaded onto barges there. The coal would subsequently be transferred from the barges to oceangoing vessels at a deepwater pier. ",
        articleImg11: "",

        subtitle12: "6. Jacksonville Port",
        mediumText12: "",
        paragraph12:
            "The possibility of using the local coal transshipment facilities is being looked at for Blount Island's potential as a coal export terminal. The viability of a coal-unloading terminal for the production of local energy is being assessed by the Jacksonville Electric Authority (JEA) and other Florida-based utility companies. According to people with knowledge of the project, the utility firms do not want to completely rule out the possibility of employing the new receiving facility as an export point as well.",
        articleImg12: "",

        subtitle13: "7. Ports in Georgia",
        mediumText13: "",
        paragraph13:
            "The Port of Savannah made the initial commitment for a new export facility at U.S. ports in the South Atlantic. On September 22, 1980, a 12- to 15-mmt/yr terminal on the 250-acre Hutchinson Island location with a price tag of $50 to $60 million was revealed. The Louis-ville and Nashville, Clinchfield, and Kentucky railroads will be used to transport coal from mines in Kentucky and southwest Virginia. The coal will also go along the Seaboard Coast Line's tracks starting in Spartanburg, South Carolina. Savannah's 38-foot channel depths and 7-foot tide are deemed sufficient for coal tankers. The Corps of Engineers is also currently assessing whether to deepen the waterway by 42 feet.",
        articleImg13: "",

        subtitle14: "8. Texas ports",
        mediumText14: "",
        paragraph14:
            "Galveston and Houston's coal export facilities are the main emphasis in the state of Texas. Orba Corp. is in charge of organizing the Pelican Island Terminal in Galveston. It leased the necessary land from the port roughly five years ago. A near-term development of 96 acres is proposed, with a backup growth area of 76 acres. Under complete development plans, a 15 mmt/yr terminal with 2,600 ft of berthing capacity and a 56 ft channel is proposed.",
        articleImg14: "",

        subtitle15: "9. California Coastal Ports",
        mediumText15: "",
        paragraph15:
            "Port administrators in California towns are optimistic about the chances of exporting coal mined in the Western States using their facilities. The Ports of Los Angeles, Long Beach, Sacramento, and Stockton, in particular, have made the strongest cases for utilizing their facilities. At the moment, just a small amount of coal is being transported to California ports for export on test runs.",
        articleImg15: "",

        subtitle16: "10. North Carolina and South Carolina ports",
        mediumText16: "",
        paragraph16:
            "One export facility is being discussed and planned by the North Carolina State Ports Authority for one of many locations, such as Morehead City or Wilmington. Talks about the viability of a 3-mmt to 8-mmt tonne/yr terminal have been held with a number of coal firms. The Seaboard Coast Line Railroad, which currently forms a part of the CSX network, provides service to the Port of Wilmington and has stated that it is willing to transport coal.",
        articleImg16: "",
    },
    {
        id: 22,
        preview: "images/article-preview-22.webp",
        tag: "top countries",
        name: "Top 10 urea producing countries (UREA-46)",
        articleImg1: "",
        paragraph1:
            "Urea has a very high nitrogen content of about 46%, making it The most significant nitrogenous fertilizer on the market. It is an organic molecule with a white crystalline structure. Since its pH is neutral, urea can be used in practically any type of soil. It is a waste product that develops naturally as a result of protein metabolism in animals, fish, amphibians, and humans. In the agriculture industry, urea is frequently used as a fertilizer and an ingredient in animal feed.",

        subtitle2: "",
        mediumText2: "",
        paragraph2:
            "Urea fertilizer helps supply nitrogen to the plant, which is intact its primary purpose. It helps encourage the growth of green leaves and gives plants a lush appearance. Urea also helps plants during the process of photosynthesis. Since urea fertilizer can only supply nitrogen and not phosphate or potassium, it is most effective in ensuring bloom growth.",
        articleImg2: "",

        subtitle3: "",
        mediumText3: "",
        paragraph3: "",
        articleImg3: "",

        tableLarge3: {
            tableHeader1: "S/N",
            tableHeader2: "Countries",
            tableHeader3: "Metric Tons",
            tableItem1: "1",
            tableItem2: "2",
            tableItem3: "3",
            tableItem4: "4",
            tableItem5: "5",
            tableItem6: "6",
            tableItem7: "7",
            tableItem8: "8",
            tableItem9: "9",
            tableItem10: "10",
            tableItem11: "India",
            tableItem12: "Russia",
            tableItem13: "Indonesia",
            tableItem14: "Pakistan",
            tableItem15: "United States",
            tableItem16: "Iran",
            tableItem17: "Canada",
            tableItem18: "Vietnam",
            tableItem19: "United Arab Emirates",
            tableItem20: "Poland",
            tableItem21: "24,282,045",
            tableItem22: "8,172,801",
            tableItem23: "7,330,091",
            tableItem24: "5,882,816",
            tableItem25: "5,703,251",
            tableItem26: "5,580,540",
            tableItem27: "4,056,762",
            tableItem28: "2,484,380",
            tableItem29: "2,289,818",
            tableItem30: "1,660,914",
        },

        subtitle4: "1. India",
        mediumText4: "",
        paragraph4:
            "India has seen a 1.5% yearly growth in urea fertilizer production since 2014. The nation produced the most urea fertilizer in 2019 with 24,282,045.33 metric tons, topping the list of other nations. The top three places in this ranking were held by Pakistan, Indonesia, and Russia. Morocco had the worst result, with annual growth averaging at -59.4%, while Nigeria had the strongest average growth over the previous five years, at +57%.",
        articleImg4: "",

        subtitle5: "2. Russia",
        mediumText5: "",
        paragraph5:
            "The production of urea fertilizer in Russia has increased by 6.4% annually since 2014. The nation's production of urea fertilizer in 2019 totaled 8,172,801 metric tons, placing it second among other nations. With 24,282,045.33 Metric Tons, an increase of 1.6% from 2018, India took the top spot in the standings in 2019. Morocco had the worst result, with annual growth averaging at -59.4%, while Nigeria had the strongest average growth over the previous five years, at +57%.",
        articleImg5: "",

        subtitle6: "3. Indonesia",
        mediumText6: "",
        paragraph6:
            "Indonesia's urea fertilizer production has increased 1.7% yearly since 2014. At 7,330,091 Metric Tons, the nation produced the third-most urea fertilizer in 2019. Russia, which was in second place and had 8,172,801 Metric Tons, overtakes Indonesia, while Pakistan is in third place with 5,882,816.9 Metric Tons. India topped the list with 24,282,045.33 metric tons in 2019, an increase of 1.6% over the previous year. Nigeria experienced the best growth over the past five years, averaging +57% year, while Morocco experienced the worst growth, averaging –59.4% annually.",
        articleImg6: "",

        subtitle7: "4. Pakistan",
        mediumText7: "",
        paragraph7:
            "Pakistan's annual growth rate for urea fertilizer production has been 3.7% since 2014. When compared to other nations, the nation produced 5,882,816.9 metric tons of urea fertilizer in 2019, placing it at number 4. The United States comes in second with 5,703,251.53 Metric Tons, surpassing Pakistan, which was previously rated third with 7,330,091 Metric Tons. With 24,282,045.33 metric tons, India took the top spot in 2019, after experiencing an increase of 1.6% from 2018. Nigeria had the best five-year average growth rate (+57 percent per year), while Morocco had the worst growth rate (-59.4 percent per year).",
        articleImg7: "",

        subtitle8: "5. United States",
        mediumText8: "",
        paragraph8:
            "The production of urea fertilizer in the US has increased by 18.9% annually since 2014. In terms of production of urea fertilizer, the nation ranked fifth overall in 2019 with 5,703,251.53 metric tons. With 5,882,816.9 metric tons, Pakistan overtakes the United States to take over fourth place. Iran comes in second with 5,580,540 metric tons. India is in first place, up 1.6% from 2018 to 24,282,045.33 metric tons. With an average growth rate of +57% over the past five years, Nigeria had the highest performance, while Morocco had the worst, at -59.4% annually.",
        articleImg8: "",

        subtitle9: "6. Iran",
        mediumText9: "",
        paragraph9:
            "Iran has produced 7% more urea fertilizer each year since 2014 overall. The nation's production of urea fertilizer in 2019 totaled 5,580,540 metric tons, placing it sixth among other nations. With 5,703,251.53 Metric Tons, the United States surpasses Iran to take up the fifth-place spot. Canada comes in second with 4,056,762 Metric Tons. With 24,282,045.33 Metric Tons, up 1.6% from 2018, India took the top spot in the standings in 2019. The top three places in this ranking were held by Pakistan, Indonesia, and Russia. The strongest average yearly increase was recorded in Nigeria (+57%), while the worst average annual growth was recorded in Morocco (-59.4%).",
        articleImg9: "",

        subtitle10: "7. Canada",
        mediumText10: "",
        paragraph10:
            "Canada has produced 2% more urea fertilizer annually since 2014. A total of 4,056,762 metric tons of urea fertilizer were produced in the nation in 2019, placing it seventh among all other nations. Iran, which had been placed sixth with 5,580,540 Metric Tons, overtakes Canada, and Vietnam comes in at 2,484,380 Metric Tons to take its place. India is in first place, up 1.6% from last year, with 24,282,045.33 metric tons. The top three places after India in this ranking were held by Pakistan, Indonesia, and Russia. ",
        articleImg10: "",

        subtitle11: "8. Vietnam",
        mediumText11: "",
        paragraph11:
            "Vietnam has seen an annual growth rate of 2.5% in urea fertilizer production since 2014. The nation produced 2,484,380 metric tons of urea fertilizer in 2019, which placed it eighth among other nations. Canada, which had a metric tonnage of 4,056,762 and was rated seventh, surpasses Vietnam, and the United Arab Emirates, with a metric tonnage of 2,289,818, is in second place. With 24,282,045.33 metric tons produced in 2019, India came in first place, an increase of 1.6% from the previous year. The top three places in this ranking were held by Pakistan, Indonesia, and Russia. ",
        articleImg11: "",

        subtitle12: "9. United Arab Emirates",
        mediumText12: "",
        paragraph12:
            "Production of Urea Fertilizer in the United Arab Emirates has increased by 3.2% annually since 2014. In terms of urea fertilizer production, the nation came in at number 9 with 2,289,818 metric tons in 2019. Poland comes in second with 1,660,914 metric tons, surpassing United Arab Emirates, which was surpassed by Vietnam, which was ranked eighth with 2,484,380 metric tons. India is in first place, up 1.6% from last year, with 24,282,045.33 metric tons. The top three places in this ranking were held by Pakistan, Indonesia, and Russia. ",
        articleImg12: "",

        subtitle13: "10. Poland",
        mediumText13: "",
        paragraph13:
            "Poland has experienced an annual growth rate of 4.9% in urea fertilizer production since 2014. With 1,660,914 Metric Tons produced in 2019, the nation ranked 10th among other nations in the production of urea fertilizer. With 2,289,818 Metric Tons, United Arab Emirates surpasses Poland to take over the ninth-place spot. Oman comes in at 1,652,539 Metric Tons. ",
        articleImg13: "",
    },
    {
        id: 23,
        preview: "images/article-preview-23.webp",
        tag: "top countries",
        name: "Top Coal Consumer Countries",
        articleImg1: "",
        paragraph1:
            "Coal is a very important resource to a lot of countries. Its usefulness has increased its value making a lot of countries produce it or at least import for consumption. Over 85% of the global coal consumption is made up of the top ten coal-using nations, with China alone using more coal than the rest of the world combined. According to the most recent data on coal production and consumption, here’s a list of the top 10 consumers of coal by country.",

        subtitle2: "1. China",
        mediumText2: "",
        paragraph2:
            "China is responsible for more than half of the global coal consumption in 2013. The nation experienced an increase in cola consumption of about 4% to 2.75 billion tonnes. China is also by far the largest producer of coal, accounting for around 47.4% of global coal production in 2013.",
        articleImg2: "",

        subtitle3: "",
        mediumText3: "",
        paragraph3:
            "Over 65% of the nation's overall energy consumption comes from coal. China, which has the most people and uses the most energy, is also the nation that imports the most coal, followed by Japan and India. Only about 6% of the coal used in China is imported. ",
        articleImg3: "",

        subtitle4: "2. USA",
        mediumText4: "",
        paragraph4: "",
        articleImg4: "",

        subtitle5: "",
        mediumText5: "",
        paragraph5:
            "US coal usage in 2013 was 651 million tonnes (Mt), which was about 12% of global coal use. The US is a net exporter of coal. In 2013, it produced about 13% of the world's coal, making it the second-largest coal-producing nation.",
        articleImg5: "",

        subtitle6: "",
        mediumText6: "",
        paragraph6:
            "Between 2010 and 2012, the nation's coal consumption was on the decline, but in 2013, as a result of rising energy demand and higher natural gas prices, it grew by 4.6%. About 39% of the electricity produced in the US in 2013 came from coal. ",
        articleImg6: "",

        subtitle7: "",
        mediumText7: "",
        paragraph7:
            "However, due to the planned retirement of several coal-fired power facilities in order to comply with the adoption of the Mercury and Air Toxics Standards (MATS), the nation's usage of coal is anticipated to decline in 2015.",
        articleImg7: "",

        subtitle8: "3. India",
        mediumText8: "",
        paragraph8:
            "India consumed 463.28Mt of coal in 2013, which is equivalent to around 8.5% of global coal consumption. It contributed 5.9% of the world's coal production in 2013 and was the fifth-largest coal producer. ",
        articleImg8: "",

        subtitle9: "",
        mediumText9: "",
        paragraph9:
            "About 44% of the nation's overall energy consumption and 68% of its electricity production are fueled by coal. After China and Japan, India is the third-largest importer of coal, and imports have increased significantly over the past ten years, particularly from South Africa, Indonesia, and Australia.",
        articleImg9: "",

        subtitle10: "4. Germany",
        mediumText10: "",
        paragraph10:
            "As of 2016, Germany consumed 257,488,593 Tons (short tons, 'st') of coal annually. The nation is ranked fourth in the world for coal consumption, using roughly 22.6% of the total 1,139,471,430 tons consumed worldwide. According to the 2016 population estimate of 82,193,768 people, Germany consumes 8,583 cubic feet of coal per person per day, or 3,132,702 cubic feet of coal annually.",
        articleImg10: "",

        subtitle11: "5. Russia",
        mediumText11: "",
        paragraph11:
            "As of 2016, Russia's annual coal consumption was 230,392,143 short tons ('st'). With a consumption of around 20.2% of the total 1,139,471,430 tons consumed worldwide, Russia is ranked fifth in the world for coal consumption. Russia utilizes 4,345 cubic feet of coal per person per day, or 1,585,899 cubic feet per person per year, based on the country's 2016 population of 145,275,383.",
        articleImg11: "",

        subtitle12: "6. Japan",
        mediumText12: "",
        paragraph12:
            "In 2013, Japan's coal consumption increased by 3.6% to 183.71Mt, which was around 3.4% of the total amount consumed worldwide. In 2016, it accounted for 18.5% of the world’s total consumption. The nation, which is the second-largest coal importer after China, generated only 1Mt of coal in 2013. Japan imports 99% of the coal it uses. ",
        articleImg12: "",

        subtitle13: "",
        mediumText13: "",
        paragraph13:
            "23% of Japan's overall energy consumption and 27% of its capacity for electricity production come from coal. Prior to China overtaking Japan as the largest coal importer in 2012, Japan had held the top spot for three decades. Following the Fukushima tragedy in 2011, coal-fired power facilities are planned to replace Japan's nuclear power capacity, causing the country's coal consumption to increase even more.",
        articleImg13: "",

        subtitle14: "7. South Africa",
        mediumText14: "",
        paragraph14:
            "As of 2016, South Africa's annual coal consumption was 202,298,474 short tons ('st'). With a consumption of around 17.8% of the total 1,139,471,430 tons consumed worldwide, South Africa is ranked seventh in the world for coal consumption. Based on the 56,207,646 population in 2016, South Africa utilizes 3,599,127 cubic feet of coal per person annually, or 9,861 cubic feet per person daily. About 30% of the coal used in South Africa is imported. ",
        articleImg14: "",

        subtitle15: "8. South Korea",
        mediumText15: "",
        paragraph15:
            "As of 2016, South Korea consumed 157,124,159 short tons ('st') of coal annually. With around 13.8% of the total global consumption of 1,139,471,430 tons of coal, South Korea is ranked eighth in the world. Based on the country's 50,983,457-person population in 2016, South Korea uses 3,081,866 cubic feet of coal per person annually, or 8,443 cubic feet per person per day. About 90% of the coal used in South Korea is imported. ",
        articleImg15: "",

        subtitle16: "9. Poland",
        mediumText16: "",
        paragraph16:
            "As of 2016, Poland's annual coal consumption was 148,799,901 short tons. With a consumption of around 13.1% of the total 1,139,471,430 tons consumed worldwide, Poland is ranked ninth in the world for coal consumption. Based on the country's 2016 population of 37,989,220, Poland consumes 10,731 cubic feet of coal per person per day, or 3,916,898 cubic feet per person annually. Poland is popular for manufacturing their own coal, they do not import from any other country. ",
        articleImg16: "",

        subtitle17: "10. Australia",
        mediumText17: "",
        paragraph17:
            "As of 2016, Australia's annual coal consumption was 129,642,679 Tons (short tons, 'st'). With a consumption of around 11.4% of the total 1,139,471,430 tons consumed worldwide, Australia is ranked 10th in the world for coal consumption. Australia produces enough coal for the nation’s use, it also exports about 77% of its coal production.",
        articleImg17: "",

        subtitle18: "Conclusion ",
        mediumText18: "",
        paragraph18:
            "According to a report by the International Energy Agency (IEA), if more efforts are not made to transition to a low-carbon economy, global coal consumption is predicted to reach an all-time high in 2022 and remain at comparable levels in the following years.",
        articleImg18: "",
    },
    {
        id: 24,
        preview: "images/article-preview-24.webp",
        tag: "top companies",
        name: "Top reputable companies that produce potash fertilizer",
        articleImg1: "",
        paragraph1:
            "Potash is the most popular source of fertilizer in the world. It is used to describe potassium-containing salts that can be used as a fertilizer. Potassium chloride (KCl) is the most common source of potash. It is also known as Muriate of Potash (MOP). Potash is mostly made up of soluble potassium. It plays a significant role in supplying nutrients to plants. Experts describe it as one of the most important agricultural resources in the world. Asides from providing nutrients for plants, it also boosts crop yield,  enhances water retention in plants, affects taste, nutritional value, and texture of various plants. ",

        subtitle2: "",
        mediumText2: "",
        paragraph2:
            "The first potash was produced by leaching tree ashes in metal pots. A white residue always appeared on the pot after the process. This residue was called “pot ash.”",
        articleImg2: "",

        subtitle3: "Differences between MOP and SOP",
        mediumText3: "",
        paragraph3: "",
        articleImg3: "",

        subtitle4: "",
        mediumText4: "",
        paragraph4:
            "MOP is the most popular type of potash. According to studies, it accounts for over 95% of agricultural potash in the world. Potassium sulfate or sulfate of potash (SOP) is the second most common type of agricultural potash. MOP and SOP are the two most common types of potash fertilizer. While they may have many similarities, they also have various properties that distinguishes them. For example, MOP is made up of half potassium, and half chloride. This means it can be applied in areas where the soil chloride content is low. It is mostly applied on carbohydrate calls, such as oats, wheat, and barley. It is also cheaper and more accessible than other potassium compounds.",
        articleImg4: "",

        subtitle5: "",
        mediumText5: "",
        paragraph5:
            "SOP is produced chemically, while MOP is mined naturally. Unlike MOP, SOP does not contain any chloride, which gives it an advantage in areas where soil content is high. For example, experts recommend using SOP in dry environments because they usually contain high chloride. SOP is used as a special fertilizer for crops, like vegetables, potatoes, tobacco, tree nuts, and fruits. It represents a smaller market than MOP, but it is more expensive. ",
        articleImg5: "",

        tableLarge5: {
            tableHeader1: "S/N",
            tableHeader2: "Company",
            tableHeader3: "Marketing capitalization",
            tableItem1: "1.",
            tableItem2: "2.",
            tableItem3: "3.",
            tableItem4: "4.",
            tableItem5: "5.",
            tableItem6: "6.",
            tableItem11: "The Mosaic Company",
            tableItem12: "Yara International ASA",
            tableItem13: "ICL Group LTD",
            tableItem14: "Uralkali",
            tableItem15: "Acron Group",
            tableItem16: "K+S Aktiengesellschaft",
            tableItem21: "$15.05 billion",
            tableItem22: "$11.03 billion",
            tableItem23: "$8.83 billion",
            tableItem24: "$5.22 billion",
            tableItem25: "$3.2 billion",
            tableItem26: "$4.7 billion",
        },

        subtitle6: "1. The Mosaic Company (NYSE:MOS)",
        mediumText6: "",
        paragraph6: "",
        articleImg6: "",

        subtitle7: "",
        mediumText7: "",
        paragraph7:
            "The Mosaic Company is one of the largest producers of concentrated potash in the United States. It is a Florida-based company with a market capitalization of $15.05 billion, and a headcount of 13,000 employees in six countries. According to a research note issued to investors this year, the Bank of America increased the price value of the Mosaic Company from $65 to $69 and generated a buy rating on the stock. Research shows that the current increase in demand for potash fertilizer in Florida would increase the production rate and supply in the company. ",
        articleImg7: "",

        subtitle8: "2. Yara International ASA (YAR.OL)",
        mediumText8: "",
        paragraph8: "",
        articleImg8: "",

        subtitle9: "",
        mediumText9: "",
        paragraph9:
            "Yara International ASA has a market capitalization of $11.03 billion. It is an oslo-based company that supplies potash fertilizer to every region in Norway. The primary objective of the company is to produce and sell fertilizers using nitrogen, phosphate, and potash as main ingredients. It is one of the most dominant potash-producing companies in the world. It has reputable branches in vital regions, such as Asia, North America, and Europe. The fertilizers are usually manufactured using various techniques, such as mining of phosphate rocks, solar evaporation, production of nitrogen using natural gas, and extraction of potash via mining. Yara is also one of the most reputable producers of ammonia and urea, two industrial products that contain high amounts of nitrogen.",
        articleImg9: "",

        subtitle10: "3. ICL Group Ltd (NYSE:ICL)",
        mediumText10: "",
        paragraph10:
            "With a market capitalization of $8.83 billion, Israel Chemicals Limited (ICL) is one of the most reputable potash-producing companies in Israel. The company uses 38 production facilities in 13 countries and has 12,000 workers. It also has sales service points in 30 different countries and 60 logistics. The company’s major potash production comes from its mines Spain and Israel. It utilizes evaporation to remove potash from the Dead Sea at Sodom, Israel. Aside from the production of potash fertilizer, the company also focuses on marketing. It uses several brand names to market its products, including “Qrop” and “PotashCorp.”",
        articleImg10: "",

        subtitle11: "",
        mediumText11: "",
        paragraph11:
            "The company’s production rate increases every year. It recorded a potash production of 4.69 million tonnes In 2022. With its global reputation, standard production facilities, and professional potash extractors, ICL continues to be one of the most reputable producers in the industry. ",
        articleImg11: "",

        subtitle12: "4. Uralkali",
        mediumText12: "",
        paragraph12:
            "Uralkali is located in Berezniki, Russia. With a market capitalization of $5.22 billion and a TTM revenue of $3.48 billion, it is one of the most reputable producers and exporters of potash. The company was established in 1930. It produces different types of fertilizers with varying concentrations of potassium chloride. It is home to seven ore-treatment facilities and five mines. The company hires over 12,000 employees. Uralkali regulates the potash value chain. It controls all the processes involved in mining potash ore and supplying end products to users. ",
        articleImg12: "",

        subtitle13: "5. Acron Group",
        mediumText13: "",
        paragraph13:
            "Acron Group has a market capitalization of $4.8 billion and a TTM revenue of $3.2 billion. It is a Russia-based company that produces a wide variety of potash fertilizers. It is one of the most renowned manufacturers of nitrogen, potassium, and phosphorus. According to the International Energy Agency, the annual production at Acron Group is 8 million tonnes. Its global impact is felt in differ parts of the world, including Argentina, China, Russia, Switzerland, and Brazil. Products from the company go to 60 countries. Some of its products include White Potash (WP) and Muriate of Potash (MOP). The company has over 11,000 employees with all the skills required to produce potash fertilizers. ",
        articleImg13: "",

        subtitle14: "6. K+S Aktiengesellschaft (SDF.DE)",
        mediumText14: "",
        paragraph14:
            "With a market capitalization of $4.17 billion, K+S Aktiengesellschaft (SDF.DE) is the biggest potash producer in Europe. It is a German-based company with production facilities in North and South America. It has over 11,000 employees. It uses the brand name “K+S KALI” to send its potash fertilizer to over 100 countries. The company’s primary objective is to provide effective and manageable solutions to improve agricultural processes. ",
        articleImg14: "",

        tableLarge14: {
            tableHeader1: "S/N",
            tableHeader2: "Company",
            tableHeader3: "TTM Revenue",
            tableItem1: "1.",
            tableItem2: "2.",
            tableItem3: "3.",
            tableItem4: "4.",
            tableItem5: "5.",
            tableItem6: "6.",
            tableItem11: "The Mosaic Company",
            tableItem12: "Yara International ASA ",
            tableItem13: "Israel Chemicals Limited (ICL)",
            tableItem14: "Uralkali",
            tableItem15: "Acron Group",
            tableItem16: "K+S Aktiengesellschaft",
            tableItem21: "$19.13 billion",
            tableItem22: "$23.90 billion",
            tableItem23: "$10.01 billion",
            tableItem24: "$3.48 billion",
            tableItem25: "$3.2 billion",
            tableItem26: "$1.5 billion",
        },

        subtitle15: "Conclusion",
        mediumText15: "",
        paragraph15:
            "Potash fertilizer is one of the most important agricultural products in the world. It is used to improve water retention, improve the taste of fruits and supply nutrients to plants. Its demand and supply rate continues to increase In various parts of the world. The companies listed in this article play significant roles in ensuring the product gets to different parts of the world. ",
        articleImg15: "",
    },
    {
        id: 25,
        preview: "images/article-preview-25.webp",
        tag: "us coal",
        name: "US Coal Market",
        articleImg1: "/images/article-25-img-1.webp",
        paragraph1:
            "75 percent of the 800 million metric tons of the U.S. energy-related carbon dioxide (CO2) emissions reduction between 2005 and 2017 was attributable to the 40 percent decline in coal-fired power output during the previous decade. The decline in natural gas prices brought on by the shale revolution, the stagnant demand for power in the United States, and, to a lesser extent, the expansion of wind and solar energy that was encouraged by policy were the key factors that led to the transition away from coal. Almost 90% of the coal used in the United States is used for power generation, therefore during the past ten years, there has been a corresponding decline in coal production.",

        subtitle2: "",
        mediumText2: "",
        paragraph2:
            "Throughout the past 100 years, there have been ups and downs in the production and consumption of coal in the United States, with reductions occurring after peaks in 1920 and 1945 and then being reversed. But, present market and policy variables indicate that the likelihood of another big recovery is low. ",
        articleImg2: "",

        subtitle3: "",
        mediumText3: "",
        paragraph3:
            "The future of the country's coal industry is still closely tied to its function in the production of electricity, where predictions indicate challenges for coal-fired plants, such as competition from cheap and plentiful natural gas, capacity expansions for wind, solar, and gas-fired power sources, and lack of growth in lectricity sales.",
        articleImg3: "",

        subtitle4: "",
        mediumText4: "",
        paragraph4:
            "Due to the high construction costs of new coal plants compared to natural gas or renewable energy sources, as well as the dispatch competition they face, it is extremely improbable that potential investors will ever recoup their costs or see a return.",
        articleImg4: "",

        subtitle5: "US Coal Market and Trends ",
        mediumText5: "",
        paragraph5:
            "The industries that make up the global coal market include Oil, Gas, & Consumable Fuels Industry. Our research experts have analyzed historical data to determine the COVID-19 pandemic's effect on the industry as well as the major market drivers. Determining end goals and improving marketing tactics to acquire a competitive edge will be made easier with the help of a comprehensive analysis of the drivers. ",
        articleImg5: "",

        subtitle6: "",
        mediumText6: "",
        paragraph6:
            "The growth extent for the projected year will be influenced by external factors that have a significant impact on the parent market's potential for growth in the upcoming years, which have been thoroughly explored in our research report.",
        articleImg6: "",

        subtitle7: "",
        mediumText7: "",
        paragraph7:
            "The market is fiercely competitive, with enterprises of all sizes fighting for market monopolization, including big, medium, and small organizations. The market's active manufacturers' significant progress in a number of areas, including product breakthroughs and new product launches, will fuel the expansion even more.",
        articleImg7: "",

        list7: {
            listItem1:
                "● Coal is used in the manufacturing of steel in about 70% of the world. Coking coal, also known as metallurgical coal, is an essential component in the production of steel. To provide the carbon needed to make iron, pulverized coal injection (PCI) technology injects coal right into the blast furnace. ",
            listItem2:
                "● 88 million metric tons of steel were produced in the United States in 2019, up from 86.6 million metric tons in 2018. The likely major driver of coal use is steel production. ",
            listItem3:
                "● The US remains the fourth-largest producer of steel in the world, despite its current steel output being lower than it was in the early 2000s.",
            listItem4:
                "● EIA estimates that the COVID-19 program will reduce the amount of coal mined in the United States from an expected 705 million tons in 2019 to 530 million tons in 2020. The drop is anticipated as a result of unfavorable market dynamics, such as declining demand for coal-fired power generation and declining steel and metallurgical coal consumption abroad. ",
            listItem5:
                "● The amount of coal consumed in the United States in the fourth quarter of 2022 was 115.8 MMst, which was 1.3% more than the fourth quarter of 2021 and 20.2% less than the 145.1 MMst reported in the third quarter of 2022. In the fourth quarter of 2022, the electric power sector accounted for nearly 90.9% of all coal consumption in the US. ",
            listItem6:
                "● Coal stocks increased by 9.9% from the end of the third quarter of 2022 to the fourth quarter of 2022, reaching 112 MMst. At the conclusion of the third quarter of 2022, the value of stocks in the electric power industry climbed to 90 MMst from 80.1 MMst.",
            listItem7:
                "● Due to a declining share in electricity generation, there will likely be a decline in coal use. After COVID-19, with a growing market for building and infrastructure, it is anticipated that steel manufacturing will increase the demand for coal. ",
        },

        subtitle8: "",
        mediumText8: "",
        paragraph8:
            "In the projection period of 2020-2025, it is anticipated that the US coal market would contract at a CAGR of more than 3%. The metallurgical, cement, and other end-user sectors are anticipated to boost the market despite declining production. Even though the amount of electricity produced by coal-fired power plants is declining, existing thermal plants are projected to use a sizable amount of coal during the forecast period. However as a result of climate change policies, the market is always seeing a decline in demand, which is why renewable energy sources are being prioritized for the production of power.",
        articleImg8: "",

        subtitle9: "Factors that might affect market growth",
        mediumText9: "",
        paragraph9:
            "The country's coal market is anticipated to be constrained by growing environmental worries about greenhouse gas emissions from burning coal and global warming in the United States and around the globe. ",
        articleImg9: "",

        subtitle10: "",
        mediumText10: "",
        paragraph10:
            "Policies addressing climate change caused a decrease in coal production, which fell to 14.30 EJ in 2019. Due to market constraints, the share is probably going to fall even further by the end of 2020. ",
        articleImg10: "",

        subtitle11: "",
        mediumText11: "",
        paragraph11:
            "EIA's projection predicts that from an estimated 55.1 million tons in 2019, the United States' metallurgical coal exports will decrease by 32.3% to 37.3 million tons in 2020. Beyond mid-2021, however, it is anticipated that both the output and export would rise. ",
        articleImg11: "",

        subtitle12: "",
        mediumText12: "",
        paragraph12:
            "The US cola market is anticipated to contract further over the forecast period due to the decline in consumption of coal brought on by environmental restrictions and an increase in the share of renewable energy sources.",
        articleImg12: "",

        subtitle13: "Top coal companies in the US",
        mediumText13:
            "The major and biggest coal companies in the US include: ",
        paragraph13: "",
        articleImg13: "",

        list13: {
            listItem1: "● Peabody Energy Corp, Arch Coal Inc",
            listItem2: "● Alliance Resources Partners",
            listItem3: "● L.P., NACCO Industries, Inc.",
            listItem4: "● Contura Energy Inc",
        },
    },
]

export default articlesArray
