// TradingViewWidget.jsx
import React, { useEffect, useRef, memo } from "react"

function TradingViewWidget() {
    const container = useRef()

    useEffect(() => {
        const script = document.createElement("script")
        script.src =
            "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js"
        script.type = "text/javascript"
        script.async = true
        script.innerHTML = `
        {
          "symbols": [
            {
              "description": "",
              "proName": "NYMEX:AGT1!"
            },
            {
              "description": "",
              "proName": "NYMEX:JKM1!"
            },
            {
              "description": "",
              "proName": "NYMEX:MTF1!"
            },
            {
              "description": "",
              "proName": "NYMEX:AZ71!"
            },
            {
              "description": "",
              "proName": "NYMEX:AGE1!"
            },
            {
              "description": "",
              "proName": "NYMEX:A1Q1!"
            },
            {
              "description": "",
              "proName": "NYMEX:EP11!"
            },
            {
              "description": "",
              "proName": "NYMEX:ALY1!"
            }
          ],
          "showSymbolLogo": true,
          "colorTheme": "dark",
          "isTransparent": false,
          "displayMode": "adaptive",
          "locale": "en"
        }`
        container.current.appendChild(script)
    }, [])

    return (
        <div class="tradingview-widget-container" ref={container}>
            <div class="tradingview-widget-container__widget"></div>
        </div>
    )
}

export default memo(TradingViewWidget)
